import linkVariant from '../../../common/styles/animationVariants/linkVariant';
import { motion } from 'framer-motion';

type TypeSubProps = {
  children: any;
  withHoverEffect?: boolean;
};

export default function TypeSub({
  children,
  withHoverEffect,
  ...rest
}: TypeSubProps) {
  return (
    <span
      className="text-[3vw] md:text-[1.6vw] lg:text:text-[1.4vw] xl:text-[0.95vw] text-left font-normal "
      {...rest}
    >
      <motion.span
        whileHover={withHoverEffect ? linkVariant : {}}
        className="no-underline"
      >
        {children}
      </motion.span>
    </span>
  );
}
