import { IconProps } from "../../types";

const LeftChevronIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="16" height="28" fill="none" viewBox="0 0 16 28">
        <path fill="#F93" d="M6.189 14l9.496 10.227a1.214 1.214 0 01-.009 1.64L13.98 27.66a1.067 1.067 0 01-1.57-.005L.326 14.826A1.192 1.192 0 010 14c0-.3.107-.597.325-.824L12.41.346A1.067 1.067 0 0113.979.34l1.697 1.791c.428.456.433 1.185.01 1.64L6.188 14z"></path>
      </svg>
    </>
  );
};

export default LeftChevronIcon;
