import axios, { AxiosRequestHeaders } from 'axios';
import { Constants } from '../../utils/constants';

type ApiCallProps = {
  url: string;
  method: any;
  accessToken?: string;
  headers?: AxiosRequestHeaders;
  data?: any;
  onSuccess: (data: any) => void;
  onError?: any;
};

const apiCall = (args: ApiCallProps) => {
  const { url, method, headers, data, accessToken, onSuccess } = args;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
  axios.defaults.baseURL = Constants.baseBackApiUrl;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
    })
    .then((data) => {
      onSuccess(data.data);
    })
    .catch((err) => {});
};

export default apiCall;
