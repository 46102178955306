const linkVariant = {
  scale: 1.1,
  color: "#ff9933",
  transition: { type: "spring", stiffness: 500 },
};

export const linkVariant2 = {
  scaleX: 1.1,
  transition: { type: "spring", stiffness: 400 },
  originX: 0,
};

export default linkVariant;
