import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PageLoading from './PageLoading';
import { isEmpty } from 'utils/misc';

type AuthWrapperProps = {
  children: any;
};

const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const [cookies] = useCookies(['accessToken']);
  const location = useLocation();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (!isEmpty(cookies) && !isEmpty(cookies['accessToken'])) {
      setAuthorized(true);
      // if (!_.isEmpty(cookies['accessToken']['userType'])) {
      //   if (
      //     cookies['accessToken']['userType'] === 'for_fund_manager' &&
      //     (!location.pathname.startsWith('/resource') || location.pathname !== '/')
      //   ) {
      //     navigate('/fund-manager').then(() => setAuthorized(true));
      //   } else if (
      //     cookies['accessToken']['userType'] === 'for_entrepreneur' &&
      //     !location.pathname.startsWith('/resource')
      //   ) {
      //     navigate('/entrepreneur').then(() => setAuthorized(true));
      //   }
      // } else {
      //   navigate('/').then(() => setAuthorized(true));
      // }
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.accessToken, location.pathname]);
  // console.log(
  //   'authhh',
  //   authorized,
  //   cookies['accessToken'] && cookies['accessToken']['userType']
  // );
  return (
    <div>
      {authorized || location.pathname === '/login' ? (
        children
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default AuthWrapper;
