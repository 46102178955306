import { Topic } from '../topicTypes';

export const sortTopics = (topicList: Topic[], userType: string) => {
  const fundComparator = (i: Topic, j: Topic) =>
    (i.fund_order | 0) - (j.fund_order | 0);
  const entComparator = (i: Topic, j: Topic) =>
    (i.ent_order | 0) - (j.ent_order | 0);

  return topicList.sort(
    userType === 'for_fund_manager' ? fundComparator : entComparator
  );
};
