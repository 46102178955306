import { combineReducers } from '@reduxjs/toolkit';
import { topicsReducer } from '../features/topic/topicSlice';
import { layoutReducer } from '../common/layout/layoutSlice';
import { loginReducer } from '../features/login/loginSlice';
import { homeReducer } from '../features/home/homeSlice';
import { resourceReducer } from '../features/resource/resourceSlice';

export default combineReducers({
  layout: layoutReducer,
  topics: topicsReducer,
  login: loginReducer,
  home: homeReducer,
  resource: resourceReducer,
});
