import { IconProps } from "../../types";

const MenuIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16"></path>
      </svg>
    </>
  );
};

export default MenuIcon;
