import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    windowInnerHeight: 0,
    appBarHeight: 0,
    windowInnerWidth: 0,
  },
  reducers: {
    setAppBarHeight: (state, action) => {
      state.appBarHeight = action.payload;
    },
    setInnerHeight: (state, action) => {
      state.windowInnerHeight = action.payload;
    },
    setInnerWidth: (state, action) => {
      state.windowInnerWidth = action.payload;
    },
  },
});

export const { setAppBarHeight, setInnerHeight, setInnerWidth } =
  layoutSlice.actions;

export const layoutSelector = (state: RootState) => state.layout;

export const layoutReducer = layoutSlice.reducer;
