import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Feature
import Header from './header/Header';

//Redux
import { setAppBarHeight, setInnerHeight, setInnerWidth } from './layoutSlice';
import { useDispatch } from 'react-redux';

//Other
import LayoutContext from '../../common/context/LayoutContext';
import AuthWrapper from 'common/components/utility/AuthWrapper';
import PageAnalytics from 'common/lib/PageAnalytics';

type LayoutProps = {
  children: any;
};

export default function Layout(props: LayoutProps) {
  const dispatch = useDispatch();
  const { children } = props;
  const [barHeight, setbarHeight] = useState(0);
  const [innerHeight, setWindowInnerHeight] = useState(0);
  const [innerWidth, setWindowInnerWidth] = useState(0);

  function handlePortHeight() {
    let clientHeight =
      document.getElementById('appBar') &&
      document.getElementById('appBar')?.clientHeight;
    dispatch(setAppBarHeight(clientHeight));
    if (clientHeight) {
      setbarHeight(clientHeight);
    }

    let vh = window.innerHeight;
    dispatch(setInnerHeight(vh));
    setWindowInnerHeight(vh);
    handlePortWidth();
  }

  function handlePortWidth() {
    let vw = window.innerWidth;
    dispatch(setInnerWidth(vw));
    setWindowInnerWidth(vw);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handlePortHeight();
      window.addEventListener('resize', handlePortHeight);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handlePortHeight);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        windowInnerHeight: innerHeight,
        appBarHeight: barHeight,
        windowInnerWidth: innerWidth,
      }}
    >
      <PageAnalytics />
      <div className="flex flex-col min-h-screen">
        <Header />
        <main
          className="flex-grow xs:w-full"
          style={{
            marginTop: `${barHeight}px`,
          }}
        >
          <AuthWrapper>{children}</AuthWrapper>
        </main>
      </div>
    </LayoutContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};
