import { IconProps } from '../../types';

function HeaderMenuIcon({ className }: IconProps) {
  return (
    <svg
      className={className || 'w-6 h-6'}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#5A5A5A"
        d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
      ></path>
    </svg>
  );
}

export default HeaderMenuIcon;
