/* eslint-disable react/jsx-no-target-blank */
import { motion } from 'framer-motion';

type PrimaryLinkProps = {
  children: any;
  url: string;
};

export default function PrimaryLink({
  url,
  children,
  ...rest
}: PrimaryLinkProps) {
  return url ? (
    <a href={url} target="_blank" rel="noreferrer" {...rest}>
      <motion.div
        className="border-2 border-primary rounded-[0.2vw] cursor-pointer inline-block text-textSecondary py-[0.1vw] pt-[0.3vw] mt-[-0.3vw] px-[1.2vw] lg:mt-[-0.4vw]"
        whileHover={{
          backgroundColor: '#ff9933',
          color: '#fff',
          transition: { duration: 0.2 },
        }}
      >
        {children}
      </motion.div>
    </a>
  ) : (
    <motion.div
      className="border-2 border-primary rounded-[0.2vw] cursor-pointer inline-block text-textSecondary py-[0.1vw] pt-[0.3vw] mt-[-0.3vw] px-[1.2vw] lg:mt-[-0.4vw]"
      whileHover={{
        backgroundColor: '#ff9933',
        color: '#fff',
        transition: { duration: 0.2 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}
