import { Link } from 'react-router-dom';
import { sortTopics } from 'features/topic/services/sortTopics';
import { useAppSelector } from 'app/hooks';
import TopicList from 'features/topic/components/TopicList';
import { topicsSelector } from 'features/topic/topicSlice';
import ResourceContainer, { TopicUserProp } from '../ResourceContainer';
import Title from '../Title';
import NextIcon from 'common/components/icons/NextIcon';

const ResourcePopularContainer = ({ topicUserType }: TopicUserProp) => {
  const { topics } = useAppSelector(topicsSelector);

  const specificTopics = (topics || []).filter(
    (item) => item && item[`${topicUserType}`]
  );

  sortTopics(specificTopics, topicUserType);

  return (
    <>
      <ResourceContainer topicUserType={topicUserType}>
        <div className="flex mx-4 my-2 items-center">
          <Link
            to={`${Title().url}`}
            className="no-underline text-textPrimary hover:text-primary mr-1"
          >
            <p>{Title().name}</p>
          </Link>
          <NextIcon className="w-3 h-3 mr-1 mb-1" />
          <Link
            to="/resource"
            className="no-underline text-textPrimary hover:text-primary mr-1"
          >
            <p>Resource</p>
          </Link>
          <NextIcon className="w-3 h-3 mr-1 mb-1" />
          <p>Popular</p>
        </div>
        <TopicList topics={specificTopics || []} title="Popular Topics" />
      </ResourceContainer>
    </>
  );
};

export default ResourcePopularContainer;
