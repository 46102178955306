import { useSendApiData } from 'common/hooks/useSendApiData';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Formik
import * as Yup from 'yup';
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik';

//Component
import TypeHeader from 'common/components/typography/TypeHeader';
import { parseRegisterValidation } from 'utils/validation';
import _ from 'lodash';

type ForgotPasswordType = {
  email: string;
};

const ForgotPassword = () => {
  const [sucess, setSucess] = useState('');
  const [forgotLoading, setForgotLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { callApi, loading } = useSendApiData('auth/forgot-password');

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const handleSubmit = async (
    values: ForgotPasswordType,
    { setFieldError }: FormikHelpers<ForgotPasswordType>
  ) => {
    setForgotLoading(true);
    await callApi({
      data: values,
      onValidationError: (err) => {
        parseRegisterValidation(err.data, setFieldError);
        setForgotLoading(false);
        if (_.isEmpty(err.data)) {
          setError('Something went wrong, try again!');
        }
      },

      onSuccess: (_d, _s) => {
        setError('');
        setSucess('Check Email for Password Reset Link');
        setForgotLoading(false);
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10">
        <div className="max-w-sm mx-auto">
          {/* <div className="min-w-full"></div> */}
          <TypeHeader>Reset Password</TypeHeader>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field name="email" className="my-[1vw]">
                    {(props: FieldProps) => {
                      const { field, meta } = props;

                      return (
                        <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                          <input
                            placeholder="Email"
                            type="email"
                            {...field}
                            required
                            className="block w-full border-b-2 my-[1vw] p-1"
                          />

                          {meta.touched && (
                            <p className="text-[#ff0000] my-0.5">
                              {meta.error}
                            </p>
                          )}
                        </div>
                      );
                    }}
                  </Field>

                  <p className="text-[green] mb-6 -mt-0.5 text-[15px]">
                    {sucess && sucess}
                  </p>

                  <p className="text-[#ff0000] mb-6 -mt-4 text-[15px]">
                    {error && error}
                  </p>

                  <div className="flex justify-between">
                    <button
                      className="bg-secondary rounded-[3px]  text-white font-bold py-1 px-10 mr-2 disabled:opacity-50"
                      onClick={() => navigate('/login')}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isSubmitting || loading || forgotLoading}
                      className="bg-primary rounded-[3px]  text-white font-bold py-1 px-10  disabled:opacity-50"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
