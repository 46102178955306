export const isObject = (objValue: any) => {
  return (
    objValue && typeof objValue === 'object' && objValue.constructor === Object
  );
};

export const isEmpty = (data: any) => {
  if (Array.isArray(data)) return data.length === 0;

  if (typeof data === 'string') return data === '';

  return data === null || data === undefined || data === {};
};
