import { useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Constants } from '../../utils/constants';
import { errorParser } from '../../utils/error';

type CallApi<T> = (endpoint: string, config?: AxiosRequestConfig) => Promise<T>;

const useFetchApi = <T>(): [CallApi<T | null>, boolean, string] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const callApi: CallApi<T | null> = async (
    endpoint: string,
    config: AxiosRequestConfig = {}
  ) => {
    let data: T | null = null;
    setLoading(true);
    setError('');
    try {
      const res = await axios.get(
        `${Constants.baseBackApiUrl}/${endpoint}`,
        config
      );
      data = res.data;
    } catch (err) {
      setError(errorParser(err as AxiosError));
    }

    setLoading(false);

    return data;
  };

  return [callApi, loading, error];
};

export default useFetchApi;
