import RouteManager from './common/containers/RouteManager';

const App = () => {
  return (
    <>
      <RouteManager />
    </>
  );
};

export default App;
