type ContainedButtonProps = {
  children: any;
  className?: string;
  handleClick?: () => void;
};

export default function ContainedButton({
  children,
  className,
  handleClick,
  ...rest
}: ContainedButtonProps) {
  return (
    <>
      <button
        className={`text-white bg-primary py-1.5 text-center px-10  text-lg relative rounded-[3px] ${className}`}
        {...rest}
        onClick={handleClick}
      >
        {children}
      </button>
    </>
  );
}
