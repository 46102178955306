const MONTHS = [
  'Jan',
  'Fev',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatBlogDate = (date: string) => {
  // const d = new Date('5/1/2020, 4:30:09 PM');
  const d = new Date(date);

  return `${MONTHS[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};
