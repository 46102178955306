import { IconProps } from "../../types";

function YoutubeIcon({ className }: IconProps) {
  return (
    <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="30" height="23" fill="none" viewBox="0 0 30 23">
      <path fill="#F00606" d="M29.164 3.954c-.277-1.582-1.6-2.734-3.133-3.094C23.737.36 19.49 0 14.893 0 10.303 0 5.986.36 3.69.86 2.159 1.22.833 2.298.556 3.954.276 5.748 0 8.264 0 11.499c0 3.234.277 5.75.625 7.547.28 1.58 1.6 2.731 3.133 3.09 2.436.505 6.613.864 11.206.864 4.597 0 8.773-.36 11.21-.863 1.53-.36 2.85-1.438 3.13-3.09.28-1.798.628-4.387.696-7.548-.14-3.235-.488-5.751-.836-7.545zM11.136 16.53V6.467l8.493 5.032-8.493 5.031z"></path>
    </svg>
  );
}

export default YoutubeIcon;
