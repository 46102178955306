import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

//Component
import WholeRenewLogo from '../../components/icons/WholeRenewLogo';
import TypeNav from '../../components/typography/TypeNav';
import PrimaryLink from '../../components/typography/PrimaryLink';

//Framer Motion
import { motion, AnimatePresence } from 'framer-motion';
import linkVariant from 'common/styles/animationVariants/linkVariant';
import {
  drawerVariantAnimate,
  drawerVariantInitial,
} from '../../styles/animationVariants/drawerVariant';

//Features
import AboutMenu from './AboutMenu';
import DrawerMenu from './DrawerMenu';
import ResourcesMenu from './ResourcesMenu';
import InvestmentMenu from './InvestmentMenu';

//Cookie
import { useCookies } from 'react-cookie';

//Component
import HeaderMenuIcon from 'common/components/icons/HeaderMenuIcon';
import ExpandIcon from 'common/components/icons/ExpandIcon';
import LoginMenu from './LoginMenu';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
  const [showRes, setShowRes] = useState(false);
  const [resClicked, setResClicked] = useState(false);
  const [showInv, setShowInv] = useState(false);
  const [invClicked, setInvClicked] = useState(false);
  const [showAbt, setShowAbt] = useState(false);
  const [abtClicked, setAbtClicked] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleMouseHover = () => {
    setShowRes(true);
    setShowInv(false);
    setShowAbt(false);
  };
  const handleMouseLeave = () => {
    if (!resClicked) {
      setShowRes(false);
    }
  };
  const handleClick = () => {
    setResClicked(true);
  };

  const handleInvMouseHover = () => {
    setShowInv(true);
    setShowRes(false);
    setShowAbt(false);
  };
  const handleInvMouseLeave = () => {
    if (!invClicked) {
      setShowInv(false);
    }
  };
  const handleInvClick = () => {
    setInvClicked(true);
  };

  const handleAbtMouseHover = () => {
    setShowAbt(true);
    setShowInv(false);
    setShowRes(false);
  };
  const handleAbtMouseLeave = () => {
    if (!abtClicked) {
      setShowAbt(false);
    }
  };
  const handleAbtClick = () => {
    setAbtClicked(true);
  };

  const handleLoginMouseHover = () => {
    setShowLogin(true);
    setShowRes(false);
    setShowInv(false);
    setShowAbt(false);
  };

  const handleLoginClick = () => {
    setLoginClicked(true);
  };

  const handleLoginMouseLeave = () => {
    setLoginClicked(false);
    if (!loginClicked) {
      setShowLogin(false);
    }
  };

  const handleLogout = () => {
    removeCookie('accessToken');
    navigate('/login');
  };

  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y <= -50 && !scrolled) {
      setScrolled(true);
    }
    if (currPos.y > -50 && scrolled) {
      setScrolled(false);
    }
  });

  return (
    <header
      id="header"
      className="z-50 w-screen fixed bg-background"
      style={{
        boxShadow: scrolled
          ? 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
          : 'none',
      }}
      onMouseLeave={() => {
        handleMouseLeave();
        handleAbtMouseLeave();
        handleInvMouseLeave();
      }}
    >
      <div className="container mx-auto">
        <div
          // className={scrolled ? clsx(classes.appBar, classes.shadow) : classes.appBar}
          className="flex flex-row items-center justify-center bg-background px-[4vw] py-[3vw] md:py-[2vw] md:px-[4vw] lg:px-[4vw] xl:px-[0vw] xl:py-[0.8vw]"
          id="appBar"
        >
          <div className="flex-grow relative">
            <WholeRenewLogo />
          </div>
          <nav className="hidden lg:block">
            <ul className="list-none p-0 m-0 flex items-center self-center">
              <div onMouseLeave={handleAbtMouseLeave}>
                <motion.li
                  className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative"
                  whileHover={linkVariant}
                >
                  <div
                    className="relative flex items-center self-center"
                    onMouseOver={handleAbtMouseHover}
                    onClick={handleAbtClick}
                    style={{
                      color: location.pathname.startsWith('/about')
                        ? '#ff9933'
                        : '#a0a0a0',
                    }}
                  >
                    <TypeNav>About</TypeNav>
                    <ExpandIcon className="w-3 h-3 ml-2 -mt-1" />
                  </div>
                </motion.li>
                {showAbt && (
                  <AboutMenu handleMouseLeave={handleAbtMouseLeave} />
                )}
              </div>
              <div onMouseLeave={handleInvMouseLeave}>
                <motion.li
                  className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative"
                  whileHover={linkVariant}
                >
                  <div
                    className="relative flex items-center self-center"
                    onMouseOver={handleInvMouseHover}
                    onClick={handleInvClick}
                    style={{
                      color: location.pathname.startsWith('/investing')
                        ? '#ff9933'
                        : '#a0a0a0',
                    }}
                  >
                    <TypeNav>What We Do</TypeNav>
                    <ExpandIcon className="w-3 h-3 ml-2 -mt-1" />
                  </div>
                </motion.li>
                {showInv && (
                  <InvestmentMenu handleMouseLeave={handleInvMouseLeave} />
                )}
              </div>

              <motion.li
                className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative"
                whileHover={linkVariant}
                style={{
                  color: location.pathname.startsWith('/portfolio')
                    ? '#ff9933'
                    : '#a0a0a0',
                }}
              >
                <a
                  href="https://renewstrategies.com/portfolio"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  <TypeNav>Portfolio</TypeNav>
                </a>
              </motion.li>

              <div onMouseLeave={handleMouseLeave}>
                <motion.li
                  className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative"
                  whileHover={linkVariant}
                >
                  <div
                    className="relative flex items-center self-center"
                    onMouseOver={handleMouseHover}
                    onClick={handleClick}
                    style={{
                      color: location.pathname.startsWith('/resources')
                        ? '#ff9933'
                        : '#a0a0a0',
                    }}
                  >
                    <TypeNav>Resources</TypeNav>

                    <ExpandIcon className="w-3 h-3 ml-2 -mt-1" />
                  </div>
                </motion.li>
                {showRes && (
                  <ResourcesMenu handleMouseLeave={handleMouseLeave} />
                )}
              </div>

              <div className="hidden xl:block">
                {!_.isEmpty(cookies.accessToken) ? (
                  <motion.li
                    className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.1vw] cursor-pointer font-normal text-primary relative"
                    whileHover={linkVariant}
                    onClick={handleLogout}
                  >
                    <TypeNav>Logout</TypeNav>
                  </motion.li>
                ) : (
                  <div onMouseLeave={handleLoginMouseLeave}>
                    <motion.li
                      className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative"
                      whileHover={linkVariant}
                    >
                      <div
                        className="relative flex items-center self-center"
                        onMouseOver={handleLoginMouseHover}
                        onClick={handleLoginClick}
                        style={{
                          color: '#ff9933',
                        }}
                      >
                        <TypeNav>Login</TypeNav>

                        <ExpandIcon className="w-3 h-3 ml-2 -mt-1" />
                      </div>
                    </motion.li>
                    {showLogin && (
                      <LoginMenu handleMouseLeave={handleLoginMouseLeave} />
                    )}
                  </div>
                )}
              </div>

              <div className="hidden xl:block">
                <li className="p-0 m-0 ml-[3vw] lg:ml-[2.5vw] top-[0.15vw] cursor-pointer font-normal text-textSecondary relative">
                  <PrimaryLink url="https://renewstrategies.com/contact">
                    <TypeNav>Contact Us</TypeNav>
                  </PrimaryLink>
                </li>
              </div>
            </ul>
          </nav>
          <div
            className="xl:hidden cursor-pointer relative text-textPrimary top-[1vw] sm:top-[0vw] lg:left-[20px] xl:left-0"
            onClick={() => setOpenMenu(true)}
          >
            <HeaderMenuIcon className="w-8 h-8" />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {openMenu && (
          <motion.nav
            initial={drawerVariantInitial}
            animate={drawerVariantAnimate}
            exit={drawerVariantInitial}
            style={{ position: 'relative', zIndex: 9999 }}
          >
            <DrawerMenu onClose={handleMenuClose} handleLogout={handleLogout} />
          </motion.nav>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
