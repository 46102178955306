type ErrorSetter = (field: string, message: string) => void;

export const parseRegisterValidation = (
  errData: any,
  fieldErrorSetter: ErrorSetter
) => {
  if (
    errData &&
    errData.length &&
    errData[0].messages &&
    errData[0].messages.length
  ) {
    errData[0].messages.forEach(({ id, message }: any) => {
      const field = id.split('.').reverse()[1];
      console.log(field, message);

      fieldErrorSetter(field, message);
    });
  }
};
