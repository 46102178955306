type TypeDrawerProps = {
  children: any;
  className?: string;
  light?: boolean;
  thin?: boolean;
};
export default function TypeDrawer({
  children,
  light,
  thin,
  className,
  ...rest
}: TypeDrawerProps) {
  return (
    <span
      className={
        light
          ? `text-[4vw] sm:text-[2.5vw] xl:text-[1.5vw] font-medium text-background`
          : `text-[4vw] sm:text-[2.5vw] xl:text-[1.5vw] font-medium`
      }
      style={{ fontWeight: thin ? 500 : 700 }}
      {...rest}
    >
      {children}
    </span>
  );
}
