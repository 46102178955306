import { IconProps } from "../../types";

const ShareIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
        <path fill="#696969" d="M11.137 0a2.652 2.652 0 00-2.651 2.652c0 .046.015.087.017.132L4.342 4.873a2.603 2.603 0 00-1.69-.63 2.652 2.652 0 000 5.303c.649 0 1.23-.242 1.69-.629l4.16 2.088c-.001.045-.016.087-.016.132a2.651 2.651 0 102.651-2.651c-.647 0-1.23.242-1.69.63l-4.16-2.089c.002-.045.016-.087.016-.132 0-.046-.014-.088-.016-.133l4.16-2.088a2.61 2.61 0 001.69.63 2.65 2.65 0 100-5.304z"></path>
      </svg>
    </>
  );
};

export default ShareIcon;
