import { useState } from 'react';

//Redux
import { useAppSelector } from 'app/hooks';
import { layoutSelector } from 'common/layout/layoutSlice';

//Component
import ContainedButton from 'common/components/buttons/ContainedButton';
import TypeHeader from 'common/components/typography/TypeHeader';

//Feature
import LoginDialog from './LoginDialog';
import SigninDialog from './SignUpDialog';

//Api
import getApiImage from 'common/services/ApiImage';
import { isEmpty } from 'utils/misc';

const LoginBanner = () => {
  const { appBarHeight } = useAppSelector(layoutSelector);
  const { banner } = useAppSelector((state) => state.login);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignupDialog, setOpenSignupDialog] = useState(false);

  const handleLoginDialogClose = () => {
    setOpenLoginDialog(false);
  };

  const handleLoginDialogOpen = () => {
    setOpenLoginDialog(true);
  };

  const handleSignupDialogClose = () => {
    setOpenSignupDialog(false);
  };

  const handleSignupDialogOpen = () => {
    setOpenSignupDialog(true);
  };

  const bannerData = banner && {
    title: banner.section1_title,
    desc: banner.section1_description,
    loginButton: banner.section1_button1,
    signupButton: banner.section1_button2,
    author1Text: banner.section1_description2,
    author1: banner.section1_author1,
    author2Text: banner.section1_description3,
    author2: banner.section1_author2,
    bannerImage: banner.image,
  };

  return (
    <>
      <div
        className={`bg-gray-200 bg-cover bg-center bg-no-repeat flex flex-col justify-center p-0 m-0`}
        style={{
          backgroundImage:
            bannerData &&
            !isEmpty(bannerData.bannerImage) &&
            !isEmpty(bannerData.bannerImage.url)
              ? `url(${getApiImage(bannerData.bannerImage.url)})`
              : 'url()',
          minHeight: `calc(100vh - ${appBarHeight}px)`,
        }}
      >
        <div
          className="bg-black bg-opacity-50 w-screen z-49 relative grid place-items-center"
          style={{
            minHeight: `calc(100vh - ${appBarHeight}px)`,
            // border: 'red solid 5px',
          }}
        >
          <div
            className="grid place-items-center w-[95%] lg:w-2/4 mt-5"
            // style={{ border: 'blue solid 5px' }}
          >
            <TypeHeader className="text-primary mb-1 md:mb-3 w-full sm:w-[80%] text-center font-bold text-[10vw] sm:text-[4vw] xl:text-[3vw]">
              {bannerData && bannerData['title']
                ? bannerData['title']
                : 'SG2X Playbook Portal'}
            </TypeHeader>

            {/* <p className=" text-white text-xl mb-6 text-center xl:text-left w-full md:w-[85%] lg:w-[95%] xl:w-full 2xl:w-[90%]">
              {' '}
              {bannerData && bannerData['desc']}
            </p> */}
            <p className="text-white mb-6 text-center font-bold text-[3.8vw] sm:text-[2.2vw] xl:text-[1.3vw] w-full md:w-[85%] lg:w-[100%] xl:w-full 2xl:w-[90%]">
              {bannerData && bannerData['desc']
                ? bannerData['desc']
                : 'The SME Gender Growth Acceleration (SG2X) Playbook is provided as part of a partnership between USAID and RENEW to provide asset managers and entrepreneurs with a set of practical tools to aid them in building gender-smart, scalable SMEs in sub-Saharan Africa.'}
            </p>

            <div className="grid place-items-center md:flex md:justify-center md:items-center mb-[1vw]">
              <ContainedButton
                className="mb-5 md:mb-0 md:mr-[4vw] block md:inline-block"
                handleClick={handleLoginDialogOpen}
              >
                {bannerData && bannerData['loginButton']
                  ? bannerData['loginButton']
                  : 'Log In'}
              </ContainedButton>
              <button
                className="text-primary underline text-[21px] font-bold mb-5 md:mb-0"
                onClick={handleSignupDialogOpen}
              >
                {bannerData && bannerData['signupButton']
                  ? bannerData['signupButton']
                  : 'Sign up'}
              </button>
            </div>
            <p className="text-lg italic text-white  text-center w-full md:w-3/4  xl:w-2/4">
              {bannerData &&
                bannerData['author1Text'] &&
                bannerData['author1Text']}
            </p>
            <span className="text-lg italic text-white text-center mb-3">
              {' '}
              {bannerData && bannerData['author1'] && bannerData['author1']}
            </span>

            <p className="text-lg italic text-white  text-center w-full md:w-3/4 xl:w-2/4">
              {bannerData &&
                bannerData['author2Text'] &&
                bannerData['author2Text']}{' '}
            </p>
            <span className="text-lg italic text-white text-center mb-3">
              {bannerData && bannerData['author2'] && bannerData['author2']}
            </span>
          </div>
        </div>
      </div>

      {openLoginDialog && <LoginDialog handleClose={handleLoginDialogClose} />}
      {openSignupDialog && (
        <SigninDialog handleClose={handleSignupDialogClose} />
      )}
    </>
  );
};

export default LoginBanner;
