import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, Link, useNavigate } from 'react-router-dom';

import cn from 'classnames';

//Component
import LeftChevronIcon from 'common/components/icons/LeftChevronIcon';
import SearchIcon from 'common/components/icons/SearchIcon';
import PageLoading from 'common/components/utility/PageLoading';

import { Topic, TopicUserType } from '../../topic/topicTypes';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setUserType, setUserTypeEnd } from 'features/home/homeSlice';

type ResourceSideBarProps = {
  topicUserType: TopicUserType;
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
  topics: Topic[];
  marginTop?: number;
  searchTerm?: string;
  onChangeSearch?: (s: string) => void;
};

const ResourceSideBar = ({
  topicUserType,
  sidebarRef,
  topics,
  marginTop,
  searchTerm,
  onChangeSearch,
}: ResourceSideBarProps) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState('');
  const { data, loading } = useAppSelector((state) => state.home.userType);
  const [cookies, setCookie] = useCookies(['accessToken']);
  const location = useLocation();
  const navigate = useNavigate();

  const hideSideNav = () => {
    sidebarRef.current?.classList.add('-translate-x-full');
  };
  const handleUserType = (type: string) => {
    if (cookies['accessToken'] && cookies['accessToken']['token']) {
      dispatch(setUserType({ type }, cookies.accessToken.token));
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setUserTypeEnd());
      // if (user === '/fund-manager') {
      //   setCookie('userType', 'for_fund_manager', { path: '/' });
      // } else if (user === '/entrepreneur') {
      //   setCookie('userType', 'for_entrepreneur', { path: '/' });
      // }
      let userType = '';
      if (user === '/fund-manager') {
        userType = 'for_fund_manager';
      } else if (user === '/entrepreneur') {
        userType = 'for_entrepreneur';
      }

      let token = {
        token: cookies['accessToken']['token'],
        userType,
      };

      setCookie('accessToken', token, { path: '/' });

      // TODO: Replace router push
      navigate(`${user}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageLoading contentLoading={loading} />
      <div
        ref={sidebarRef}
        className={cn(
          'border-r-2  w-72 px-3 py-2 fixed inset-y-0 left-0 transform -translate-x-full lg:translate-x-0 transition duration-200 z-10 bg-gray-100 overflow-y-auto'
        )}
        style={{ marginTop: marginTop }}
      >
        <div className="w-full grid place-items-center">
          <button
            className="hover:text-primary"
            onClick={() => {
              if (topicUserType === 'for_fund_manager') {
                handleUserType('entrepreneur');
                setUser('/entrepreneur');
              } else if (topicUserType === 'for_entrepreneur') {
                handleUserType('fundmanager');
                setUser('/fund-manager');
              }
            }}
          >
            {topicUserType === 'for_fund_manager'
              ? 'Switch to Entrepreneur'
              : 'Switch to Fund Manager'}
          </button>
        </div>
        <div className="lg:hidden flex flex-row justify-end py-2 pr-2">
          <span onClick={hideSideNav}>
            <LeftChevronIcon className="w-5 h-5 text-primary cursor-pointer" />
          </span>
        </div>

        {location.pathname === '/resource' && (
          <div className="relative flex flex-wrap mt-2">
            <input
              type="text"
              placeholder="Search"
              className="px-6 py-3 placeholder-secondary font text-gray-900 relative bg-white bg-opacity-20 rounded-full text-sm border border-secondary outline-none focus:outline-none focus:ring w-full"
              value={searchTerm}
              onChange={(e) => onChangeSearch && onChangeSearch(e.target.value)}
            />
            <span className="z-10 leading-snug font-normal text-center text-secondary absolute bg-transparent rounded text-base items-center justify-center w-8 right-3 pr-4 py-3 cursor-pointer">
              <SearchIcon className="h-5 w-5" />
            </span>
          </div>
        )}

        <p className="ml-2 text-[20px] mt-[1rem]">
          {' '}
          {topicUserType === 'for_fund_manager'
            ? 'Fund Manager Content'
            : 'Entrepreneur Content'}
        </p>

        <div className="pl-4 flex flex-col ">
          <Link
            to="/resource"
            className={cn('sidebar-link', {
              active: location.pathname === '/resource',
            })}
          >
            Home
          </Link>
          <Link
            to="/resource/popular"
            className={cn('sidebar-link', {
              active: location.pathname === '/resource/popular',
            })}
          >
            Popular
          </Link>
          <p className="mt-2 text-[18px]">Topics</p>

          <div className="pl-3 flex flex-col">
            {topics.map(({ id, title }) => (
              <span
                key={id}
                className={cn(
                  'border-b  pb-2 mb-3',
                  location.pathname === `/resource/topic/${id}`
                    ? 'border-primary'
                    : 'border-gray-300'
                )}
              >
                <Link
                  to={`/resource/topic/${id}`}
                  className={cn('sidebar-link tracking-tighter text-sm', {
                    active: location.pathname === `/resource/topic/${id}`,
                  })}
                >
                  {title}
                </Link>
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceSideBar;
