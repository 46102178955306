import { IconProps } from "../../types";

const PdfIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none" viewBox="0 0 18 23">
        <path
          fill="#FF5722"
          d="M3.6 0C1.617 0 0 1.59 0 3.539V19.46C0 21.411 1.617 23 3.6 23h10.8c1.983 0 3.6-1.59 3.6-3.539V7.077c0-.94-.876-1.831-2.447-3.345-.218-.211-.453-.446-.677-.664-.22-.217-.46-.42-.674-.635C12.663.888 11.755 0 10.8 0H3.6zm0 1.768h6.552c.652.164.648.93.648 1.714v2.711c0 .486.403.884.9.884h2.7c.896 0 1.8.004 1.8.884v11.5c0 .98-.806 1.77-1.8 1.77H3.6c-.996 0-1.8-.79-1.8-1.77V3.54c0-.978.804-1.77 1.8-1.77zm4.724 6.193c-.39 0-.783.207-.956.527-.172.317-.186.673-.141 1.049.071.584.412 1.3.76 1.991-.184.642-.278 1.22-.562 1.907-.348.844-.771 1.51-1.182 2.212-.54.256-1.21.485-1.602.746-.43.288-.779.54-.957.912a.865.865 0 00-.029.663c.088.225.275.392.479.499.416.217.876.082 1.237-.165.362-.25.706-.627 1.04-1.08.177-.241.336-.626.507-.912.537-.242.907-.505 1.519-.719.851-.296 1.624-.407 2.418-.58.645.442 1.316.775 2.053.775.423 0 .746-.003 1.04-.167.297-.163.452-.535.452-.83a.916.916 0 00-.253-.635 1.314 1.314 0 00-.591-.331c-.421-.114-.893-.09-1.464-.028-.29.031-.689.186-1.012.249-.084-.067-.168-.039-.253-.11-.7-.61-1.342-1.436-1.828-2.267-.036-.063-.021-.104-.055-.167.12-.456.34-.984.392-1.383.068-.52.068-.984-.055-1.382-.06-.196-.137-.403-.31-.553a1.014 1.014 0 00-.647-.22zm-.112.885c.014-.007.043 0 .112 0 .05 0 .022-.007.029 0 .007.007.055.049.084.138.057.18.059.539 0 .995-.004.035-.022.102-.029.139-.066-.218-.26-.522-.28-.692-.032-.272.02-.463.055-.524.018-.032.015-.05.029-.056zm.394 3.76a9.888 9.888 0 001.405 1.687c-.639.165-1.233.242-1.883.47-.212.072-.33.172-.535.248.217-.443.482-.778.674-1.244.17-.41.198-.753.34-1.162zm4.359 2.047c.159-.004.282.001.366.026a.4.4 0 01.168.084c.01.009 0-.003 0 .027 0 .131.021.045 0 .056-.02.01-.222.054-.591.054-.146 0-.323-.183-.477-.221.182-.01.375-.025.534-.026zm-7.566 2.349c-.19.217-.389.48-.534.58-.217.151-.267.132-.31.111-.048-.025-.023-.028-.027-.028.052-.086.266-.318.618-.553.057-.038.19-.072.253-.11z"
        ></path>
      </svg>
    </>
  );
};

export default PdfIcon;
