import Layout from 'common/layout/Layout';
import TopicPage from './TopicPage';

const EntrepreneurPage = () => {
  return (
    <Layout>
      <TopicPage topicUserType="for_entrepreneur" />
    </Layout>
  );
};

export default EntrepreneurPage;
