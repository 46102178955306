import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageLoading from 'common/components/utility/PageLoading';
import ErrorContainer from 'common/containers/ErrorContainer';
import BlogList from 'features/blog/components/BlogList';
import TopicBanner from '../components/TopicBanner';
import TopicList from '../components/TopicList';
import useFetchTopics from '../services/useFetchTopics';
import { TopicUserType } from '../topicTypes';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchTopicData, topicsSelector } from '../topicSlice';

import { isEmpty } from 'utils/misc';
import Search from '../components/Search';

type TopicPageProps = {
  topicUserType: TopicUserType;
};

const TopicPage = ({ topicUserType }: TopicPageProps) => {
  const [topics, topicsLoading, topicsError] = useFetchTopics(topicUserType);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { topicData } = useAppSelector(topicsSelector);
  const [searchStarted, setSearchStarted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (location.pathname === '/entrepreneur') {
      dispatch(fetchTopicData('/entrepreneur'));
    } else if (location.pathname === '/fund-manager') {
      dispatch(fetchTopicData('/fund-manager'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSearchStart = (term: string) => {
    setSearchTerm(term);
    setSearchStarted(true);
  };

  return (
    <>
      <PageLoading contentLoading={topicsLoading} />

      {searchStarted ? (
        <Search term={searchTerm} onClear={() => setSearchStarted(false)} />
      ) : (
        <>
          <TopicBanner onStartSearch={handleSearchStart} />
          <ErrorContainer message={topicsError} onRetry={() => {}}>
            <TopicList
              title={
                topicData && !isEmpty(topicData.section2_title)
                  ? topicData.section2_title
                  : 'The Relevant Topics'
              }
              topics={topics || []}
            />
          </ErrorContainer>
          <BlogList
            title={
              topicData && !isEmpty(topicData.section3_title)
                ? topicData.section3_title
                : 'Some Relevant Blogs'
            }
          />
        </>
      )}
    </>
  );
};

export default TopicPage;
