import { useCookies } from 'react-cookie';
import ResourcePopularContainer from '../containers/ResourcePopularContainer';
import { isEmpty } from 'utils/misc';

const ResourcePopularPage = () => {
  const [cookies] = useCookies(['accessToken']);
  return (
    <>
      <ResourcePopularContainer
        topicUserType={
          !isEmpty(cookies['accessToken']) &&
          !isEmpty(cookies['accessToken']['userType'])
            ? cookies['accessToken']['userType']
            : 'for_entrepreneur'
        }
      />
    </>
  );
};

export default ResourcePopularPage;
