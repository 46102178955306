import { IconProps } from "../../types";

const PresentationIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none" viewBox="0 0 18 23">
        <path
          fill="#FF6D00"
          d="M3.6 0C1.617 0 0 1.59 0 3.539V19.46C0 21.411 1.617 23 3.6 23h10.8c1.983 0 3.6-1.59 3.6-3.539V7.077c0-.94-.876-1.831-2.447-3.345-.218-.211-.453-.446-.677-.664-.22-.217-.46-.42-.674-.635C12.663.888 11.755 0 10.8 0H3.6zm0 1.768h6.552c.652.164.648.93.648 1.714v2.711c0 .486.403.884.9.884h2.7c.896 0 1.8.004 1.8.884v11.5c0 .98-.806 1.77-1.8 1.77H3.6c-.996 0-1.8-.79-1.8-1.77V3.54c0-.978.804-1.77 1.8-1.77zm2.98 6.774a.756.756 0 00-.674.83c.045.41.425.709.844.663h1.8c1.507 0 2.254.253 2.53.47.279.218.31.442.31.995 0 .405-.19.77-.478 1.05-.289.28-.672.415-.815.415-1.062 0-1.407-.286-1.576-.47-.084-.093-.113-.162-.141-.221l-.027-.055c-.01-.017-.014-.049-.057-.11-.01-.018-.034-.032-.055-.056a2.855 2.855 0 00-.086-.083l-.196-.138-.422-.029-.394.195-.168.193s-.018.08-.028.11c-.011.032-.025.064-.029.083-.014.08-.029.114-.029.139-.003.045 0 .056 0 .082v4.535c0 .41.341.746.76.746a.754.754 0 00.76-.746v-2.986c.446.18.995.304 1.688.304.707 0 1.36-.35 1.883-.856.524-.509.928-1.24.928-2.102 0-.716-.168-1.586-.9-2.156-.73-.57-1.799-.802-3.459-.802H6.581z"
        ></path>
      </svg>
    </>
  );
};

export default PresentationIcon;
