import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { DialogData, LoginData } from './loginTypes';

//Api
import apiCall from 'common/services/ApiCall';
import {
  fetchLoginApiUrl,
  fetchLoginDialogApiUrl,
  fetchSignupDialogApiUrl,
} from './services/login';

type LoginSlice = {
  banner: LoginData | null;
  loginDialog: DialogData | null;
  signupDialog: DialogData | null;
};

const initialState: LoginSlice = {
  banner: null,
  loginDialog: null,
  signupDialog: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginData: (state, { payload }: PayloadAction<LoginData | null>) => {
      state.banner = payload;
    },
    setLoginDialogData: (
      state,
      { payload }: PayloadAction<DialogData | null>
    ) => {
      state.loginDialog = payload;
    },
    setSignupDialogData: (
      state,
      { payload }: PayloadAction<DialogData | null>
    ) => {
      state.signupDialog = payload;
    },
  },
});

export const { setLoginData, setLoginDialogData, setSignupDialogData } =
  loginSlice.actions;

export const fetchLoginData = (): AppThunk => (dispatch) => {
  apiCall({
    url: fetchLoginApiUrl,
    method: 'GET',
    onSuccess: (data) => {
      dispatch(setLoginData(data));
    },
  });
};

export const fetchLoginDialogData = (): AppThunk => (dispatch) => {
  apiCall({
    url: fetchLoginDialogApiUrl,
    method: 'GET',
    onSuccess: (data) => {
      dispatch(setLoginDialogData(data));
    },
  });
};

export const fetchSignupDialogData = (): AppThunk => (dispatch) => {
  apiCall({
    url: fetchSignupDialogApiUrl,
    method: 'GET',
    onSuccess: (data) => {
      dispatch(setSignupDialogData(data));
    },
  });
};

export const loginReducer = loginSlice.reducer;
