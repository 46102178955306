const resourceMenuVariant = {
  initial: {
    opacity: 0.5,
    scaleY: 0,
  },
  animate: {
    opacity: 1,
    scaleY: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.5,
  },
};

export default resourceMenuVariant;
