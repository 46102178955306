import ClockLoader from 'react-spinners/ClockLoader';

type PageLoadingProps = {
  contentLoading?: boolean;
};

export default function PageLoading({ contentLoading }: PageLoadingProps) {
  return contentLoading ? (
    <div
      className="top-0 bottom-0 left-0 right-0 fixed h-full bg-white bg-opacity-70 grid place-items-center z-[10000]"
      // style={{ height: `${windowInnerHeight - appbarHeight}px` }}
    >
      <ClockLoader color={`#ff9933`} loading={true} size={60} />
    </div>
  ) : (
    <div></div>
  );
}
