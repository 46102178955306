import { Constants } from 'utils/constants';
import { clipText } from 'utils/textUtils';
import CustomLink from 'common/components/utility/CustomLink';
import { getImageUrl } from 'utils/image';
import { StrapiImage } from 'common/types';
import { formatBlogDate } from 'utils/time';

export type BlogCardProp = {
  title: string;
  content: string;
  date: string;
  blogLink: string;
  image: StrapiImage;
};

const BlogCard = ({ title, content, date, blogLink, image }: BlogCardProp) => {
  return (
    <>
      <div className="max-w-full h-96 sm:max-w-sm mx-8 sm:mx-4 border-2 rounded-xl overflow-hidden my-12 flex flex-col">
        {image ? (
          <img
            className="object-cover w-full max-h-44"
            src={getImageUrl(image)}
            alt="Blog cover"
          />
        ) : (
          <div className="w-full h-44 bg-gray-400" />
        )}
        <div className="pb-3 flex-1 px-4 pt-2 flex flex-col justify-between">
          <div>
            <h6 className="text-lg mt-2 font-bold text-gray-900">{title}</h6>
            <p className="">
              {clipText(content, Constants.maxBlogContentLength)}
            </p>
          </div>

          <div className="flex flex-row justify-between mt-6">
            <p>{formatBlogDate(date)}</p>
            <CustomLink href={blogLink} className="no-underline">
              <span className="text-primary ">View</span>
            </CustomLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
