import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

//Features
import ResourceList from './ResourceList';

//Redux
import { useAppSelector } from 'app/hooks';
import { topicsSelector } from 'features/topic/topicSlice';

import { Constants } from '../../../utils/constants';
import { Resource, Topic } from 'features/topic/topicTypes';
import { hasResource } from 'utils/textUtils';
import BlogList from './BlogList';
import { getImageUrl } from 'utils/image';
import Title from '../Title';
import NextIcon from 'common/components/icons/NextIcon';
import imgify from '../imagify';

export type ResourceType =
  | 'all'
  | 'video'
  | 'presentation'
  | 'spreadsheet'
  | 'pdf'
  | 'document';

type ResourceContentProps = {
  activeResource: ResourceType;
  onSelectResource: (type: ResourceType) => void;
};

const ResourceContent = ({
  activeResource,
  onSelectResource,
}: ResourceContentProps) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [topic, setTopic] = useState<Topic | null>(null);
  const { topics } = useAppSelector(topicsSelector);
  let description = '';

  const { id } = useParams();

  useEffect(() => {
    topics?.forEach((topic) => {
      if (topic.id === parseInt(id as string, 10)) {
        setTopic(topic);
      }
    });
  }, [id, topics]);

  useEffect(() => {
    // console.log('filter res', resourceId);

    if (topic) {
      let curResources = topic.resources;
      // if (resourceId) {
      //   curResources = curResources.filter(
      //     (item) => item.id === parseInt(resourceId as string, 10)
      //   );
      // } else {
      // }
      // console.log(curResources);
      curResources = curResources.filter(
        (item) => activeResource === 'all' || item.type === activeResource
      );

      setResources(curResources);
    }
  }, [topic, activeResource]);

  if (topic) {
    description = topic.description;
  }

  return (
    <>
      <div className="flex mx-4 my-2 items-center">
        <Link
          to={`${Title().url}`}
          className="no-underline text-textPrimary hover:text-primary mr-1"
        >
          <p>{Title().name}</p>
        </Link>
        <NextIcon className="w-3 h-3 mr-1 mb-1" />
        <Link
          to="/resource"
          className="no-underline text-textPrimary hover:text-primary mr-1"
        >
          <p>Resource</p>
        </Link>
        <NextIcon className="w-3 h-3 mr-1  mb-1" />
        <p className="mr-1">Topic</p>
        <NextIcon className="w-3 h-3 mr-1  mb-1" />
        <p className="mr-1">{topic && topic.title}</p>
      </div>
      <h2 className="mx-4 my-2">{topic && topic.page_title}</h2>
      {topic && !_.isEmpty(topic.photo) && (
        <div className="mx-4 mb-2 px-8 py-2 ">
          <img
            className="object-contain object-center w-full max-h-96"
            src={getImageUrl(topic.photo)}
            alt="Topic Page Cover"
          />
        </div>
      )}

      <div className="px-8 py-2 shadow-md mx-4 mb-2 border border-l-8 border-primary">
        <div
          dangerouslySetInnerHTML={{
            __html: imgify(description, Constants.baseBackApiUrl, 'my-8'),
          }}
          id="description"
        />
      </div>
      <div className="flex flex-row ml-4">
        <div className="flex flex-row items-end flex-wrap">
          <button
            className={classnames('resource-btn', {
              active: activeResource === 'all',
            })}
            onClick={() => onSelectResource('all')}
          >
            All Content
          </button>
          {topic && hasResource(topic.resources, 'video') && (
            <button
              className={classnames('resource-btn', {
                active: activeResource === 'video',
              })}
              onClick={() => onSelectResource('video')}
            >
              Videos
            </button>
          )}
          {topic && hasResource(topic.resources, 'pdf') && (
            <button
              className={classnames('resource-btn', {
                active: activeResource === 'pdf',
              })}
              onClick={() => onSelectResource('pdf')}
            >
              PDFs
            </button>
          )}
          {topic && hasResource(topic.resources, 'presentation') && (
            <button
              className={classnames('resource-btn', {
                active: activeResource === 'presentation',
              })}
              onClick={() => onSelectResource('presentation')}
            >
              Presentations
            </button>
          )}
          {topic && hasResource(topic.resources, 'spreadsheet') && (
            <button
              className={classnames('resource-btn', {
                active: activeResource === 'spreadsheet',
              })}
              onClick={() => onSelectResource('spreadsheet')}
            >
              Spreadsheets
            </button>
          )}
          {topic && hasResource(topic.resources, 'document') && (
            <button
              className={classnames('resource-btn', {
                active: activeResource === 'document',
              })}
              onClick={() => onSelectResource('document')}
            >
              Documents
            </button>
          )}
        </div>
      </div>

      {topic && <ResourceList resources={resources} />}
      {topic && !_.isEmpty(topic.topic_blogs) && (
        <BlogList title="Some Relevant Blogs" data={topic.topic_blogs} />
      )}
    </>
  );
};

export default ResourceContent;
