import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Redux
import { useAppSelector } from 'app/hooks';
import { topicsSelector } from 'features/topic/topicSlice';
import { resourceSelector } from '../resourceSlice';

import TopicList from 'features/topic/components/TopicList';
import { Topic } from 'features/topic/topicTypes';
import ResourceContainer, { TopicUserProp } from '../ResourceContainer';
import { sortTopics } from 'features/topic/services/sortTopics';
import Title from '../Title';
import NextIcon from 'common/components/icons/NextIcon';

const ResourceHomePage = ({ topicUserType }: TopicUserProp) => {
  const [filteredTopics, setFilteredTopics] = useState<Topic[]>([]);
  const { topics } = useAppSelector(topicsSelector);
  const { searchTerm } = useAppSelector(resourceSelector);

  useEffect(() => {
    const lowTerm = searchTerm.toLocaleLowerCase();

    const specificTopics = (topics || []).filter(
      (item) => item && item[`${topicUserType}`]
    );

    sortTopics(specificTopics, topicUserType);

    const filtered = (specificTopics || []).filter(
      (item: Topic) =>
        (item.title || '').toLowerCase().match(lowTerm) ||
        (item.description || '').toLocaleLowerCase().match(lowTerm) ||
        (item.resourceTitles || '').toLocaleLowerCase().match(lowTerm)
    );

    setFilteredTopics(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics, searchTerm]);

  return (
    <>
      <ResourceContainer topicUserType={topicUserType}>
        <div className="flex mx-4 my-2 items-center">
          <Link
            to={`${Title().url}`}
            className="no-underline text-textPrimary hover:text-primary mr-1"
          >
            <p>{Title().name}</p>
          </Link>
          <NextIcon className="w-3 h-3 mr-1 mb-1" />
          <p>Resource</p>
        </div>
        <TopicList topics={filteredTopics} title="Topics" />
      </ResourceContainer>
    </>
  );
};

export default ResourceHomePage;
