import { Link } from 'react-router-dom';

//Utils
import { hasResource } from 'utils/textUtils';

//Icons
import PdfIcon from 'common/components/icons/PdfIcon';
import PresentationIcon from 'common/components/icons/PresentationIcon';
import YoutubeIcon from 'common/components/icons/YoutubeIcon';
import WordDocIcon from 'common/components/icons/WordDocIcon';
import SpreadSheetIcon from 'common/components/icons/SpreadSheetIcon';

import { Topic } from '../topicTypes';

type TopicCardProp = {
  topic: Topic;
};

const TopicCard = ({ topic }: TopicCardProp) => {
  const { id, title, description, resources } = topic;

  return (
    <>
      <div className="border p-5 shadow-md sm:max-w-sm rounded-[10px] flex flex-col h-48">
        <div className="flex-1">
          <h3 className="text-xl font-bold line-clamp-1 mb-1">{title}</h3>
          <div className="line-clamp-4 h-24 overflow-y-hidden">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </div>
        </div>
        <div className="mt-3 flex flex-row justify-between">
          <ul className="flex space-x-2">
            {hasResource(resources, 'video') ? (
              <YoutubeIcon className="w-5 h-5  text-red-700" />
            ) : (
              ''
            )}
            {hasResource(resources, 'pdf') ? (
              <PdfIcon className="w-5 h-5  text-red-700" />
            ) : (
              ''
            )}
            {hasResource(resources, 'spreadsheet') ? (
              <SpreadSheetIcon className="w-5 h-5  text-blue-400" />
            ) : (
              ''
            )}
            {hasResource(resources, 'presentation') ? (
              <PresentationIcon className="w-5 h-5  text-blue-400" />
            ) : (
              ''
            )}
            {hasResource(resources, 'document') ? (
              <WordDocIcon className="w-5 h-5  text-red-700" />
            ) : (
              ''
            )}
          </ul>

          <Link to={`/resource/topic/${id}`} className="no-underline">
            <span className="text-primary ">View Material</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopicCard;
