import { Resource } from 'features/topic/topicTypes';
import ResourceCard from './ResourceCard';

type ResourceListProps = {
  resources: Resource[];
};

const ResourceList = ({ resources }: ResourceListProps) => {
  const orderedResources = resources.sort((a, b) =>
    !a.priority ? 1 : a.priority - b.priority
  );

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3  gap-y-4 justify-center py-6 md:py-10 px-4">
        {orderedResources.map((item) => (
          <div key={item.id}>
            <ResourceCard resource={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ResourceList;
