type TypeHeaderProps = {
  children: any;
  className?: string;
  underline?: boolean;
};

export default function TypeHeader({
  children,
  underline,
  className,
  ...rest
}: TypeHeaderProps) {
  return underline ? (
    <span
      className={`text-[25px] font-medium text-left underline ${className}`}
      {...rest}
    >
      {children}
    </span>
  ) : (
    <span
      className={`text-[25px] font-medium text-left ${className}`}
      {...rest}
    >
      {children}
    </span>
  );
}
