import { useCookies } from 'react-cookie';
import _ from 'lodash';

export default function Title() {
  const [cookies] = useCookies(['accessToken']);
  let view = {
    url: '',
    name: '',
  };
  if (
    !_.isEmpty(cookies['accessToken']) &&
    !_.isEmpty(cookies['accessToken']['userType'])
  ) {
    if (cookies['accessToken']['userType'] === 'for_fund_manager') {
      view = {
        name: 'Fund Manager',
        url: '/fund-manager',
      };
    } else if (cookies['accessToken']['userType'] === 'for_entrepreneur')
      view = {
        name: 'Entrepreneur',
        url: '/entrepreneur',
      };
  }

  return view;
}
