import { IconProps } from "../../types";

function ExpandIcon({ className }: IconProps) {
  return (
    <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="16" height="10" fill="none" viewBox="0 0 16 10">
      <path fill="#F93" fillRule="evenodd" d="M.47.47a.75.75 0 011.06 0L8 6.94 14.47.47a.75.75 0 111.06 1.06L8 9.06.47 1.53a.75.75 0 010-1.06z" clipRule="evenodd"></path>
    </svg>
  );
}

export default ExpandIcon;
