import { useCookies } from 'react-cookie';
import { isEmpty } from 'utils/misc';
import ResourceHomeContainer from '../containers/ResourceHomeContainer';

const Resource = () => {
  const [cookies] = useCookies(['accessToken']);
  return (
    <>
      <ResourceHomeContainer
        topicUserType={
          !isEmpty(cookies['accessToken']) &&
          !isEmpty(cookies['accessToken']['userType'])
            ? cookies['accessToken']['userType']
            : 'for_entrepreneur'
        }
      />
    </>
  );
};

export default Resource;
