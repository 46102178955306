import React from 'react';

const NextIcon: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  return (
    <svg
      className={className || 'w-3 h-3'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2L8 6L4 10"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NextIcon;
