import { useEffect, useState } from 'react';
import qs from 'qs';
import PageLoading from 'common/components/utility/PageLoading';
import useFetchApi from 'common/hooks/useFetchApi';
import { Resource, Topic } from '../topicTypes';
import TopicList from './TopicList';
import ResourceList from 'features/resource/components/ResourceList';
import TypeHeader from 'common/components/typography/TypeHeader';

type SearchProps = {
  term: string;
  onClear: () => void;
};
const Search = ({ term, onClear }: SearchProps) => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);

  const [callTopicApi, topicLoading] = useFetchApi<Topic[]>();
  const [callResourceApi, resourceLoading] = useFetchApi<Resource[]>();

  const searchContent = async () => {
    callTopicApi(`topics?title_contains=${term}`).then((res) => {
      setTopics(res || []);
    });

    const query = qs.stringify({
      _where: {
        _or: [{ title_contains: term }, { description_contains: term }],
      },
    });

    console.log(`resources?${query}`);
    callResourceApi(`resources?${query}`).then((res) => {
      setResources(res || []);
    });
  };

  useEffect(() => {
    searchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageLoading contentLoading={topicLoading || resourceLoading} />
      <div className="min-h-screen">
        <button
          onClick={onClear}
          className="bg-primary rounded-[3px]  text-white font-bold py-1 px-10 cursor-pointer ml-2"
          type="button"
        >
          Clear Search
        </button>
        <TopicList topics={topics} title="Topics" />

        <div>
          <div className="flex items-center justify-center mt-[3rem]">
            <TypeHeader className="text-center">Resources</TypeHeader>
          </div>
          <ResourceList resources={resources} />
        </div>
      </div>
    </>
  );
};

export default Search;
