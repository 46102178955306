import RefreshIcon from "../components/icons/RefreshIcon";
import { ReactChildren } from "../types";

type ErrorContainerProps = {
  message?: string;
  onRetry?: () => void;
} & ReactChildren;

const ErrorContainer = ({ message, children, onRetry }: ErrorContainerProps) => {
  return (
    <>
      {message ? (
        <div className="w-full flex justify-center items-center my-4">
          <div className="border-2 border-red-600 rounded-md p-3  max-w-sm">
            <p className="font-semibold text-center">{message}</p>
            {onRetry && (
              <div className="flex justify-center mt-2">
                <button onClick={onRetry} className="flex flex-row items-center bg-gray-300 px-8 py-1 rounded-md shadow-md border-gray-500 border">
                  <span className="font-semibold">Retry</span> <RefreshIcon className="w-6 h-6 ml-2" />
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorContainer;
