import { ReactChildren } from '../../types';

type CustomLinkProps = {
  href: string;
  className?: string;
} & ReactChildren;

const CustomLink = ({ href, children, className }: CustomLinkProps) => {
  return (
    <>
      <a
        href={href}
        className={`${className}`}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </>
  );
};

export default CustomLink;
