import { useEffect, useState } from 'react';

//Component
import TypeHeader from 'common/components/typography/TypeHeader';
import PageLoading from 'common/components/utility/PageLoading';

import ErrorContainer from 'common/containers/ErrorContainer';

import { Blog } from 'features/blog/blog';
import BlogCard from 'features/blog/components/BlogCard';

type BlogListProps = {
  title: string;
  data: Array<Blog>;
};

const BlogList = ({ title, data }: BlogListProps) => {
  const [blogs, setBlogs] = useState<Blog[] | null>(null);

  useEffect(() => {
    (async () => {
      if (!blogs) {
        data?.sort((a, b) => (!a.priority ? 1 : a.priority - b.priority));
        setBlogs(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-[2rem]">
      <div className="flex items-center justify-center mt-[3rem]">
        <TypeHeader>{title}</TypeHeader>
      </div>
      <PageLoading />
      <ErrorContainer>
        <div className="flex flex-row justify-center items-center flex-wrap space-y-6 sm:space-y-0">
          {blogs ? (
            blogs.map(({ title, description, publishdate, link, image }, i) => (
              <BlogCard
                key={`${title}-${i}`}
                title={title}
                content={description}
                date={publishdate}
                blogLink={link}
                image={image}
              />
            ))
          ) : (
            <h6 className="text-secondary my-6">No blogs at the moment</h6>
          )}
        </div>
      </ErrorContainer>
    </div>
  );
};

export default BlogList;
