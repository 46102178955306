import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type ResourceSlice = {
  searchTerm: string;
};

const initialState: ResourceSlice = {
  searchTerm: '',
};

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
  },
});

export const { setSearchTerm } = resourceSlice.actions;

export const resourceSelector = (state: RootState) => state.resource;

export const resourceReducer = resourceSlice.reducer;
