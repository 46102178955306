import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const PageAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    console.log('loc', location.pathname);
    ReactGA.initialize('G-1LRZ3CH4K7');
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
  console.log('loc', location.pathname);
  return <div></div>;
};

export default PageAnalytics;
