import { useCookies } from 'react-cookie';

import { isEmpty } from '../../../../src/utils/misc';
import ResourceDetailsContainer from '../containers/ResourceDetailsContainer';

const ResourceDetailPage = () => {
  const [cookies] = useCookies(['accessToken']);

  return (
    <>
      <ResourceDetailsContainer
        topicUserType={
          !isEmpty(cookies['accessToken']) &&
          !isEmpty(cookies['accessToken']['userType'])
            ? cookies['accessToken']['userType']
            : 'for_entrepreneur'
        }
      />
    </>
  );
};

export default ResourceDetailPage;
