import React, { useRef } from 'react';

import ErrorContainer from 'common/containers/ErrorContainer';
import { ReactChildren } from 'common/types';
import useFetchTopics from 'features/topic/services/useFetchTopics';
import { TopicUserType } from 'features/topic/topicTypes';
import ResourceSideBar from './components/ResourceSideBar';
import Layout from 'common/layout/Layout';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { layoutSelector } from 'common/layout/layoutSlice';
import { resourceSelector, setSearchTerm } from './resourceSlice';

//Component
import PageLoading from 'common/components/utility/PageLoading';
import MenuIcon from 'common/components/icons/MenuIcon';
import { sortTopics } from 'features/topic/services/sortTopics';

export type TopicUserProp = {
  topicUserType: TopicUserType;
};

type ResourceContainerProp = TopicUserProp & ReactChildren;

const ResourceContainer = ({
  children,
  topicUserType,
}: ResourceContainerProp) => {
  const dispatch = useAppDispatch();
  const sidebarRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const { appBarHeight } = useAppSelector(layoutSelector);
  const { searchTerm } = useAppSelector(resourceSelector);

  const [topics, topicsLoading, topicError] = useFetchTopics(
    topicUserType,
    'all'
  );

  const specificTopics = (topics || [])?.filter(
    (item) => item && item[`${topicUserType}`]
  );

  sortTopics(specificTopics, topicUserType);

  const toggleSideNav = () => {
    sidebarRef.current?.classList.toggle('-translate-x-full');
  };

  return (
    <>
      <Layout>
        <div
          className="bg-gray-100 z-0 flex flex-row max-w-screen"
          style={{
            minHeight: `calc(100vh - ${appBarHeight}px)`,
          }}
        >
          <PageLoading contentLoading={topicsLoading} />
          <ErrorContainer message={topicError}>
            <>
              <ResourceSideBar
                topicUserType={topicUserType}
                sidebarRef={sidebarRef}
                topics={specificTopics || []}
                marginTop={appBarHeight}
                searchTerm={searchTerm}
                onChangeSearch={(term) => dispatch(setSearchTerm(term))}
              />
              <div className="ml-0 lg:ml-72 w-full">
                <div className="transition duration-200  w-full pt-1 sm:pt-4 md:pt-6">
                  <button
                    className=" ml-4 block lg:hidden"
                    onClick={toggleSideNav}
                  >
                    <MenuIcon />
                  </button>
                  {children}
                </div>
              </div>
            </>
          </ErrorContainer>
          {/* </Loading> */}
        </div>
      </Layout>
    </>
  );
};

export default ResourceContainer;
