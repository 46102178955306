import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

//Framer Motion
import { motion, AnimatePresence } from 'framer-motion';

//Component
import TypeDrawer from '../../components/typography/TypeDrawer';
import FilledCloseIcon from '../../components/icons/FilledCloseIcon';
import ExpandIcon from '../../components/icons/ExpandIcon';
import ChevronRightIcon from '../../components/icons/ChevronRightIcon';
import { isEmpty } from '../../../utils/misc';

type DrawerMenuProps = {
  onClose: () => void;
  handleLogout: () => void;
};

export default function DrawerMenu(props: DrawerMenuProps) {
  const { onClose, handleLogout } = props;
  const location = useLocation();
  const [cookies] = useCookies(['accessToken']);
  const [showInvestingMenu, setShowInvestingMenu] = useState(true);
  const [showResourcesMenu, setShowResourcesMenu] = useState(true);
  const [showAboutMenu, setShowAboutMenu] = useState(true);
  const [showLoginMenu, setShowLoginMenu] = useState(true);

  return (
    <div className="fixed top-0 left-0 right-0 overflow-auto h-screen bg-background">
      <nav>
        <ul className="text-center list-none p-0 m-0 mt-[15vw] xl:m-0">
          <li className="mt-[2vw] mb-[6vw] text-[5vw] text-textSecondary font-medium">
            <button color="primary" onClick={onClose}>
              <FilledCloseIcon className="w-8 h-8 sm:w-16 sm:h-16" />
            </button>
          </li>
          <li className="mt-[2vw] text-[5vw] text-primary font-medium">
            <div
              className="ml-[3vw] relative flex justify-center font-light"
              style={{
                color: location.pathname.startsWith('/about')
                  ? '#ff9933'
                  : '#a0a0a0',
              }}
              onClick={() => setShowAboutMenu(!showAboutMenu)}
            >
              About
              <div className="ml-[2vw] mt-[1.7vw]">
                {showAboutMenu ? (
                  <ExpandIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                ) : (
                  <ChevronRightIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                )}
              </div>
            </div>
            <AnimatePresence>
              {showAboutMenu && (
                <motion.div
                  initial={{ scaleY: 0, opacity: 0 }}
                  animate={{ scaleY: 1, originY: 0, opacity: 1 }}
                  exit={{ scaleY: 0, opacity: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <ul className="text-center list-none p-0 m-0 mb-[4vw]">
                    <li>
                      <a
                        href="https://renewstrategies.com/about/overview"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">
                          Overview
                        </TypeDrawer>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://renewstrategies.com/about/teams"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">Team</TypeDrawer>
                      </a>
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </li>
          <li className="mt-[2vw] text-[5vw] text-primary font-medium">
            <div
              className="ml-[3vw] relative flex justify-center font-light"
              style={{
                color: location.pathname.startsWith('/investing')
                  ? '#ff9933'
                  : '#a0a0a0',
              }}
              onClick={() => setShowInvestingMenu(!showInvestingMenu)}
            >
              What We Do
              <div className="ml-[2vw] mt-[1.7vw]">
                {showInvestingMenu ? (
                  <ExpandIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                ) : (
                  <ChevronRightIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                )}
              </div>
            </div>
            <AnimatePresence>
              {showInvestingMenu && (
                <motion.div
                  initial={{ scaleY: 0, opacity: 0 }}
                  animate={{ scaleY: 1, originY: 0, opacity: 1 }}
                  exit={{ scaleY: 0, opacity: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <ul className="text-center list-none p-0 m-0 mb-[4vw]">
                    <li>
                      <a
                        href="https://renewstrategies.com/what-we-do/investing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">
                          Investing
                        </TypeDrawer>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://renewstrategies.com/what-we-do/market-building"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">
                          Market Building
                        </TypeDrawer>
                      </a>
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </li>

          <li
            className="mt-[2vw] text-[5vw] text-textSecondary font-light"
            style={{ color: '#a0a0a0' }}
          >
            <a
              href="https://renewstrategies.com/portfolio"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary no-underline"
            >
              Portfolio
            </a>
          </li>
          <li className="mt-[2vw] text-[5vw] text-primary font-medium">
            <div
              className="ml-[3vw] relative flex justify-center font-light"
              style={{
                color: location.pathname.startsWith('/investing')
                  ? '#ff9933'
                  : '#a0a0a0',
              }}
              onClick={() => setShowResourcesMenu(!showResourcesMenu)}
            >
              Resources
              <div className="ml-[2vw] mt-[1.7vw]">
                {showResourcesMenu ? (
                  <ExpandIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                ) : (
                  <ChevronRightIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                )}
              </div>
            </div>
            <AnimatePresence>
              {showResourcesMenu && (
                <motion.div
                  initial={{ scaleY: 0, opacity: 0 }}
                  animate={{ scaleY: 1, originY: 0, opacity: 1 }}
                  exit={{ scaleY: 0, opacity: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <ul className="text-center list-none p-0 m-0 mb-[4vw]">
                    <li className="m-0 p-0">
                      <a
                        href="https://renewstrategies.com/resources/blogs"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">Blog </TypeDrawer>
                      </a>
                    </li>

                    <li className="m-0 p-0">
                      <a
                        href="https://renewstrategies.com/resources/in-the-news"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">
                          In the News{' '}
                        </TypeDrawer>
                      </a>
                    </li>
                    <li className="m-0 p-0">
                      <a
                        href="https://renewstrategies.com/resources/galleries?category=all-images"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary no-underline"
                      >
                        <TypeDrawer className="text-center">
                          Gallery{' '}
                        </TypeDrawer>
                      </a>
                    </li>
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </li>
          {!isEmpty(cookies.accessToken) ? (
            <li
              className="mt-[2vw] text-[5vw] text-textSecondary font-normal"
              style={{ color: '#a0a0a0' }}
              onClick={() => {
                handleLogout();
                onClose();
              }}
            >
              Logout
            </li>
          ) : (
            <li className="mt-[2vw] text-[5vw] text-primary font-medium">
              <div
                className="ml-[3vw] relative flex justify-center font-light"
                style={{
                  color: location.pathname.startsWith('/investing')
                    ? '#ff9933'
                    : '#a0a0a0',
                }}
                onClick={() => setShowLoginMenu(!showLoginMenu)}
              >
                Login
                <div className="ml-[2vw] mt-[1.7vw]">
                  {showLoginMenu ? (
                    <ExpandIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                  ) : (
                    <ChevronRightIcon className="w-3 h-3 sm:w-5 sm:h-5 md:w-6 md:h-6" />
                  )}
                </div>
              </div>
              <AnimatePresence>
                {showLoginMenu && (
                  <motion.div
                    initial={{ scaleY: 0, opacity: 0 }}
                    animate={{ scaleY: 1, originY: 0, opacity: 1 }}
                    exit={{ scaleY: 0, opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <ul className="text-center list-none p-0 m-0 mb-[4vw]">
                      <li className="m-0 p-0" onClick={onClose}>
                        <Link to="/login" className="text-primary no-underline">
                          <TypeDrawer className="text-center">
                            SG2X Portal
                          </TypeDrawer>
                        </Link>
                      </li>
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </li>
          )}
          <li className="mt-[2vw] text-[5vw] font-medium">
            <a
              href="https://renewstrategies.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary no-underline"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
