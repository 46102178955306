import { IconProps } from "../../types";

function ChevronRightIcon({ className }: IconProps) {
  return (
    <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path stroke="#F93" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4l8 8-8 8"></path>
    </svg>
  );
}

export default ChevronRightIcon;
