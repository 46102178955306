//Framer Motion
import { motion } from 'framer-motion';
import resourceMenuVariant from '../../../common/styles/animationVariants/resourceMenuVariant';

//Component
import TypeSub from '../../../common/components/typography/TypeSub';

type ResourcesMenuProps = {
  handleMouseLeave: () => void;
};

export default function ResourcesMenu(props: ResourcesMenuProps) {
  const { handleMouseLeave } = props;
  return (
    <motion.div
      onMouseLeave={handleMouseLeave}
      className="z-50 bg-background rounded-sm shadow w-72 mt-1 -ml-32 px-8 py-4 pb-0.5 fixed  hover:cursor-default "
      initial={resourceMenuVariant.initial}
      animate={resourceMenuVariant.animate}
      exit={resourceMenuVariant.exit}
    >
      <ul className="p-0 m-0 list-none">
        <li className="mb-1 cursor-pointer text-secondary font-normal">
          <a
            href="https://renewstrategies.com/resources/blogs"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary no-underline"
          >
            <TypeSub withHoverEffect>Blog</TypeSub>
          </a>
        </li>
        <li className="mb-1 cursor-pointer text-secondary font-normal">
          <a
            href="https://renewstrategies.com/resources/in-the-news"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary no-underline"
          >
            <TypeSub withHoverEffect> In the News</TypeSub>
          </a>
        </li>
        <li className="mb-1 cursor-pointer text-secondary font-normal">
          <a
            href="https://renewstrategies.com/resources/galleries?category=all-images"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary no-underline"
          >
            <TypeSub withHoverEffect>Gallery</TypeSub>
          </a>
        </li>
      </ul>
    </motion.div>
  );
}
