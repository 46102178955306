import { useSendApiData } from 'common/hooks/useSendApiData';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastError, toastMessage } from 'utils/alert';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [password, setPassword] = useState('');
  const { callApi, loading } = useSendApiData('auth/reset-password');

  const code = search.get('code');

  const handleSubmit = async () => {
    if (password.length < 6) {
      toastError('Password must contain at least 6 characters');
    } else {
      await callApi({
        data: { password, passwordConfirmation: password, code },
        onError: (_) => toastError('Something went wrong try again'),
        onSuccess: (_d, _s) => {
          toastMessage('Success, password reset');
          navigate('/login', { replace: true });
        },
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10">
        <div className="max-w-sm mx-auto">
          {code ? (
            <form>
              <div className="">
                <input
                  placeholder="New Password"
                  min="6"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full border-b-2 my-[1vw]"
                />
              </div>

              <div className="flex justify-between">
                <button
                  disabled={loading}
                  className="bg-primary rounded-[3px]  text-white font-bold py-1 px-10  disabled:opacity-50"
                  type="button"
                  onClick={handleSubmit}
                >
                  Change Password
                </button>
              </div>
            </form>
          ) : (
            <p>Reset link not found, check email again</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
