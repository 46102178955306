import { useEffect } from 'react';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setTopics, topicsSelector } from '../topicSlice';

//Api
import useApi from 'common/hooks/useApi';

import { Topic, TopicUserType } from '../topicTypes';

type TopicReFetcher = () => Promise<void>;

type UseFetchTopics = (
  type: TopicUserType,
  filter?: string
) => [Topic[] | null, boolean, string, TopicReFetcher];

/**
 *
 * @param topicUserType type of user
 * @returns [
 * topics: Topic[],
 *  loading: boolean, api loading indicator
 *  topicError: string api failure error
 *  topic fetcher in case of need for refetch
 * ]
 */
const useFetchTopics: UseFetchTopics = (
  topicUserType: TopicUserType,
  filter?: string
) => {
  const dispatch = useAppDispatch();
  const { topics } = useAppSelector(topicsSelector);
  const [callApi, topicsLoading, topicError] = useApi<Topic[] | null>('topics');

  const sortTopics = (topicList: Topic[], userType: string) => {
    const fundComparator = (i: Topic, j: Topic) =>
      (i.fund_order | 0) - (j.fund_order | 0);
    const entComparator = (i: Topic, j: Topic) =>
      (i.ent_order | 0) - (j.ent_order | 0);

    return topicList.sort(
      userType === 'for_fund_manager' ? fundComparator : entComparator
    );
  };
  const fetchTopics: TopicReFetcher = async () => {
    const topics =
      (await callApi({
        params: { [filter ? '' : topicUserType || '']: true },
      })) || [];
    sortTopics(topics, topicUserType);
    dispatch(setTopics(topics));
  };

  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicUserType]);

  return [topics, topicsLoading, topicError, fetchTopics];
};

export default useFetchTopics;
