import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomeData } from './homeTypes';
import { AppThunk } from 'app/store';

//Api
import apiCall from 'common/services/ApiCall';

type HomeSlice = {
  banner: HomeData | null;
  userType: UserTypeState;
};

interface UserTypeState {
  data: null | any;
  loading: boolean;
  error: null | any;
}

const initialState: HomeSlice = {
  banner: null,
  userType: {
    data: null,
    loading: false,
    error: null,
  },
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeData: (state, { payload }: PayloadAction<HomeData | null>) => {
      state.banner = payload;
    },
    //Set UserType
    setUserTypeStart: (state) => {
      state.userType.loading = true;
      state.userType.error = null;
    },
    setUserTypeSuccess: (state, action: PayloadAction<object>) => {
      state.userType.data = action.payload;
      state.userType.loading = false;
    },
    setUserTypeFailure: (state) => {
      state.userType.error = null;
      state.userType.loading = false;
    },
    setUserTypeEnd: (state) => {
      state.userType.loading = false;
      state.userType.error = null;
      state.userType.data = null;
    },
  },
});

export const {
  setHomeData,
  setUserTypeStart,
  setUserTypeSuccess,
  setUserTypeFailure,
  setUserTypeEnd,
} = homeSlice.actions;

export const fetchHomeData = (): AppThunk => (dispatch) => {
  apiCall({
    url: '/landing-page',
    method: 'GET',
    onSuccess: (data) => {
      dispatch(setHomeData(data));
    },
  });
};

export const setUserType =
  (data: object, accessToken: string): AppThunk =>
  (dispatch) => {
    dispatch(setUserTypeStart());
    apiCall({
      url: '/set-user-type',
      method: 'POST',
      accessToken,
      data,
      onSuccess: (data) => {
        dispatch(setUserTypeSuccess(data));
      },
    });
  };

export const homeReducer = homeSlice.reducer;
