import { BrowserRouter, Route, Routes } from 'react-router-dom';

//Pages
import HomePage from 'features/home/pages/HomePage';
import LoginPage from 'features/login/pages/LoginPage';
import ForgotPasswordPage from 'features/login/pages/ForgotPasswordPage';
import EntrepreneurPage from 'features/topic/pages/EntrepreneurPage';
import FundManagerPage from 'features/topic/pages/FundManagerPage';
import ResourceHomePage from 'features/resource/pages/ResourceHomePage';
import ResourcePopularPage from 'features/resource/pages/ResourcePopularPage';
import ResourceDetailPage from 'features/resource/pages/ResourceDetailPage';
import ResetPasswordPage from 'features/login/pages/ResetPasswordPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/entrepreneur" element={<EntrepreneurPage />} />
        <Route path="/fund-manager" element={<FundManagerPage />} />
        <Route path="/resource" element={<ResourceHomePage />} />
        <Route path="/resource/popular" element={<ResourcePopularPage />} />
        <Route path="/resource/topic/:id" element={<ResourceDetailPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
