import TypeHeader from 'common/components/typography/TypeHeader';
import { isEmpty } from 'utils/misc';
import { Topic } from '../topicTypes';
import TopicCard from './TopicCard';

type TopicListProps = {
  topics: Topic[];
  title: string;
};

const TopicList = ({ topics, title }: TopicListProps) => {
  return (
    <>
      <div className="flex items-center justify-center mt-[3rem]">
        <TypeHeader className="text-center">{title}</TypeHeader>
      </div>

      {!isEmpty(topics) ? (
        <div className="grid grid-cols-1 px-8 md:px-4 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 grid-flow-row  mx-auto max-w-5xl py-8">
          {topics.map((topic, i) => (
            <TopicCard key={topic.id} topic={topic} />
          ))}
        </div>
      ) : (
        <div className="py-8">
          <h6 className="text-secondary text-center">Topics not present</h6>
        </div>
      )}
    </>
  );
};

export default TopicList;
