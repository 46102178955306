import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import apiCall from 'common/services/ApiCall';
import { Topic, TopicStaticData } from './topicTypes';

type TopicsSlice = {
  topics: Topic[] | null;
  topicData: TopicStaticData | null;
};

const initialState: TopicsSlice = {
  topics: null,
  topicData: null,
};

const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    setTopics: (state, { payload }: PayloadAction<Topic[] | null>) => {
      if (payload) {
        payload = payload.map((topic) => {
          topic.resourceTitles = topic.resources
            .map((item) => item.title)
            .join(' ');
          return topic;
        });
      }
      state.topics = payload;
    },
    setTopicStaticData: (
      state,
      { payload }: PayloadAction<TopicStaticData | null>
    ) => {
      state.topicData = payload;
    },
  },
});

export const { setTopics, setTopicStaticData } = topicsSlice.actions;

export const topicsSelector = (state: RootState) => state.topics;

export const fetchTopicData =
  (path: string): AppThunk =>
  (dispatch) => {
    if (path === '/entrepreneur') {
      apiCall({
        url: '/entrepreneur',
        method: 'GET',
        onSuccess: (data) => {
          dispatch(setTopicStaticData(data));
        },
      });
    } else if (path === '/fund-manager') {
      apiCall({
        url: '/fund-manager',
        method: 'GET',
        onSuccess: (data) => {
          dispatch(setTopicStaticData(data));
        },
      });
    }
  };

export const topicsReducer = topicsSlice.reducer;
