import { Resource } from '../features/topic/topicTypes';

export const clipText = (content: string, maxLength: number) =>
  (content || '').length < maxLength
    ? content
    : `${content.substring(0, maxLength)}..`;

export const titleText = (content: string) =>
  content && content.length
    ? `${content.charAt(0).toUpperCase()}${content.substring(1)}`
    : '';

export const hasResource = (resources: Resource[], type: string) =>
  !!resources.find((i) => i.type === type);
