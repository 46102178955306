import { IconProps } from "../../types";

const DownloadIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
        <path fill="#696969" d="M7.193 0C6.11 0 5.23.88 5.23 1.962v5.23H3.106c-.922 0-1.117.149-.327.941l4.311 4.31c1.439 1.438 1.42 1.444 2.861 0l4.29-4.31c.79-.792.527-.94-.305-.94h-2.167V1.962A1.962 1.962 0 009.809 0H7.192zM0 12.424v2.615C0 16.122.88 17 1.962 17h13.077A1.96 1.96 0 0017 15.04v-2.616h-1.307v2.615c0 .36-.294.654-.654.654H1.962a.656.656 0 01-.655-.654v-2.615H0z"></path>
      </svg>
    </>
  );
};

export default DownloadIcon;
