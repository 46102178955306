import { useEffect } from 'react';
import Layout from 'common/layout/Layout';
import BlogList from 'features/blog/components/BlogList';
import HomeBanner from '../components/HomeBanner';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchHomeData } from '../homeSlice';
import { isEmpty } from 'utils/misc';

const HomePage = () => {
  const dispatch = useAppDispatch();
  const { banner } = useAppSelector((state) => state.home);
  useEffect(() => {
    dispatch(fetchHomeData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <HomeBanner />
      <BlogList
        title={
          banner && !isEmpty(banner.section3_title)
            ? banner.section3_title
            : 'Some Relevant Blogs'
        }
      />
    </Layout>
  );
};

export default HomePage;
