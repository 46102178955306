// import filesize from 'filesize';
import { Resource } from 'features/topic/topicTypes';
// import { titleText } from 'utils/textUtils';
import { Constants } from 'utils/constants';

//Icon
import ShareIcon from 'common/components/icons/ShareIcon';
import PdfIcon from 'common/components/icons/PdfIcon';
import WordDocIcon from 'common/components/icons/WordDocIcon';
import PresentationIcon from 'common/components/icons/PresentationIcon';
import DownloadIcon from 'common/components/icons/DownloadIcon';
import SpreadSheetIcon from 'common/components/icons/SpreadSheetIcon';

type ResourceCardProp = {
  resource: Resource;
};

const ResourceCard = ({
  resource: { type, title, description, file },
}: ResourceCardProp) => {
  return (
    <>
      <div className=" max-w-full md:max-w-sm  h-32 sm:h-28 lg:h-[8.5rem] 2xl:h-28  flex flex-row border-2 shadow-md p-3  rounded-md">
        {/* <div className="h-full grid place-items-center"> */}
        <div
          className="h-full w-[10rem] sm:w-[5rem] object-cover bg-gray-300 justify-center items-center flex flex-col rounded-[8px] overflow-hidden"

          // className="bg-gray-300 w-full h-64"
        >
          {type === 'video' && (
            <>
              <iframe
                src="https://www.youtube.com/embed/tgbNymZ7vqY"
                className="max-w-full"
                title="Video link"
              ></iframe>
            </>
          )}
          {type === 'pdf' && <PdfIcon className="w-8 h-8" />}
          {type === 'spreadsheet' && <SpreadSheetIcon className="w-8 h-8" />}
          {type === 'document' && <WordDocIcon className="w-8 h-8" />}
          {type === 'presentation' && <PresentationIcon className="w-8 h-8" />}
        </div>
        {/* </div> */}

        <div className=" pl-2 flex flex-col justify-between pb-1 w-full">
          <div>
            <h2 className="text-lg font-bold">{title}</h2>
            {/* {type === 'video' ? (
              <p className="text-[15px] text-secondary line-clamp-2">
                {description}
              </p>
            ) : (
              <p className="text-secondary tracking">
                {titleText(type)} {file && filesize(file.size)}
              </p>
            )} */}
          </div>

          {type === 'video' ? (
            <>
              <a
                className="flex flex-row justify-start items-center text-secondary"
                href={file ? `${Constants.baseBackApiUrl}${file.url}` : ''}
              >
                <ShareIcon className="w-5 h-5 cursor-pointer" />
              </a>
            </>
          ) : (
            file && (
              <div className="flex flex-row justify-between items-end">
                <div>
                  <a
                    className="flex flex-row justify-start items-center text-secondary"
                    href={`${Constants.baseBackApiUrl}${file.url}`}
                  >
                    <DownloadIcon className="w-5 h-5" />{' '}
                    <span className="underline pl-2 text-[18px]">Download</span>
                  </a>
                </div>
                <ShareIcon className="w-5 h-5 cursor-pointer" />
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceCard;
