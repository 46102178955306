import { IconProps } from '../../types';
function SpreadSheetIcon({ className }: IconProps) {
  return (
    <svg
      className={className || 'w-6 h-6'}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0FA958"
        d="M8 10v1.5h8V10H8zM8 14v-1.5h2V14H8zM8 15v1.5h2V15H8zM11 15v1.5h2V15h-2zM14 15v1.5h2V15h-2zM14 14v-1.5h2V14h-2zM13 14h-2v-1.5h2V14z"
      ></path>
      <path
        fill="#0FA958"
        fillRule="evenodd"
        d="M15 2H5.5a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V6h-3.5a.5.5 0 01-.5-.5V2zM7 9.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V17a.5.5 0 01-.5.5h-9A.5.5 0 017 17V9.5z"
        clipRule="evenodd"
      ></path>
      <path fill="#0FA958" d="M18.707 5H16V2.293L18.707 5z"></path>
    </svg>
  );
}

export default SpreadSheetIcon;
