import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

//Redux
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { setUserType, setUserTypeEnd } from '../homeSlice';

//Component
import TypeHeader from 'common/components/typography/TypeHeader';
import getApiImage from 'common/services/ApiImage';
import PageLoading from 'common/components/utility/PageLoading';
import { isEmpty } from 'utils/misc';

const HomeBanner = () => {
  const { appBarHeight, windowInnerWidth } = useAppSelector(
    (state: RootState) => state.layout
  );
  const navigate = useNavigate();
  const { banner } = useAppSelector((state) => state.home);
  const { data, loading } = useAppSelector((state) => state.home.userType);
  const dispatch = useAppDispatch();
  const [cookies, setCookie] = useCookies(['accessToken']);
  const [user, setUser] = useState('');

  const handleUserType = (type: string) => {
    if (cookies.accessToken && cookies.accessToken.token) {
      dispatch(setUserType({ type }, cookies.accessToken.token));
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setUserTypeEnd());
      navigate(`${user}`);
      let userType = '';
      if (user === '/fund-manager') {
        userType = 'for_fund_manager';
      } else if (user === '/entrepreneur') {
        userType = 'for_entrepreneur';
      }

      let token = {
        token: cookies['accessToken']['token'],
        userType,
      };
      setCookie('accessToken', token, { path: '/' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageLoading contentLoading={loading} />
      <div className="block md:flex items-center justify-center">
        <div
          className={`bg-gray-200 bg-cover bg-center bg-no-repeat flex flex-col justify-center p-0 m-0 w-full lg:w-2/4`}
          style={{
            backgroundImage:
              banner && !isEmpty(banner.image1)
                ? `url(${getApiImage(banner.image1.url)})`
                : 'url()',
            height:
              windowInnerWidth <= 1024
                ? windowInnerWidth < 640
                  ? `calc((100vh - ${appBarHeight}px)/2)`
                  : `calc((100vw - ${appBarHeight}px)/2)`
                : `calc(100vh - ${appBarHeight}px)`,
          }}
        >
          <div
            className="bg-black bg-opacity-30 h-full w-full z-49 relative"
            onClick={() => {
              handleUserType('fundmanager');
              setUser('/fund-manager');
            }}
          >
            <div className="absolute bottom-[10vw] left-[30%] sm:left-[35%] md:left-[45%]">
              <h3 className="text-center text-white text-[25px]">
                {banner && banner['section1_title1']
                  ? banner['section1_title1']
                  : 'Are you a'}
              </h3>
              <TypeHeader
                className="text-center text-white cursor-pointer"
                underline
              >
                {banner && banner['section1_header1']
                  ? banner['section1_header1']
                  : 'Fund Manager'}
              </TypeHeader>
            </div>
          </div>
        </div>
        <div
          className={` bg-gray-200 bg-cover bg-center bg-no-repeat flex flex-col justify-center p-0 m-0 w-full lg:w-2/4`}
          style={{
            backgroundImage:
              banner && !isEmpty(banner.image2)
                ? `url(${getApiImage(banner.image2.url)})`
                : 'url()',
            height:
              windowInnerWidth <= 1024
                ? windowInnerWidth < 640
                  ? `calc((100vh - ${appBarHeight}px)/2)`
                  : `calc((100vw - ${appBarHeight}px)/2)`
                : `calc(100vh - ${appBarHeight}px)`,
          }}
        >
          <div
            className="bg-black bg-opacity-30 h-full w-full z-49 relative"
            onClick={() => {
              handleUserType('entrepreneur');
              setUser('/entrepreneur');
            }}
          >
            <div className="absolute bottom-[10vw] left-[30%] sm:left-[35%] md:left-[45%]">
              <h3 className="text-center text-white text-[25px]">
                {banner && banner['section1_title2']
                  ? banner['section1_title2']
                  : 'Are you an'}
              </h3>
              <TypeHeader
                className="text-center text-white cursor-pointer"
                underline
              >
                {banner && banner['section_header2']
                  ? banner['section_header2']
                  : 'Entrepreneur'}
              </TypeHeader>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
