import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

//Component
import ContainedButton from 'common/components/buttons/ContainedButton';
import SearchIcon from 'common/components/icons/SearchIcon';
import PageLoading from 'common/components/utility/PageLoading';

//Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { layoutSelector } from 'common/layout/layoutSlice';
import { topicsSelector } from '../topicSlice';
import { setUserType, setUserTypeEnd } from 'features/home/homeSlice';

//Api
import getApiImage from 'common/services/ApiImage';
import { isEmpty } from 'utils/misc';

type TopicBannerProps = {
  onStartSearch: (t: string) => void;
};

const TopicBanner = ({ onStartSearch }: TopicBannerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { appBarHeight } = useAppSelector(layoutSelector);
  const { topicData } = useAppSelector(topicsSelector);
  const { data, loading } = useAppSelector((state) => state.home.userType);
  const [cookies, setCookie] = useCookies(['accessToken']);
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState('');

  const handleClick = () => {
    navigate('/resource');
  };

  const handleUserType = (type: string) => {
    if (cookies['accessToken'] && cookies['accessToken']['token']) {
      dispatch(setUserType({ type }, cookies.accessToken.token));
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setUserTypeEnd());
      // if (user === '/fund-manager') {
      //   setCookie('userType', 'for_fund_manager', { path: '/' });
      // } else if (user === '/entrepreneur') {
      //   setCookie('userType', 'for_entrepreneur', { path: '/' });
      // }
      let userType = '';
      if (user === '/fund-manager') {
        userType = 'for_fund_manager';
      } else if (user === '/entrepreneur') {
        userType = 'for_entrepreneur';
      }

      let token = {
        token: cookies['accessToken']['token'],
        userType,
      };

      setCookie('accessToken', token, { path: '/' });

      // TODO: Replace router push
      navigate(`${user}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function makeTitle() {
    let title = '';
    if (topicData && !isEmpty(topicData.section1_title)) {
      title = topicData.section1_title;
    } else if (location.pathname === '/entrepreneur') {
      title = 'Entrepreneur';
    } else if (location.pathname === '/fund-manager') {
      title = 'Fund Manager';
    }

    return title;
  }

  return (
    <>
      <PageLoading contentLoading={loading} />
      <div
        className="bg-gray-200 flex flex-col justify-center"
        style={{
          backgroundImage: topicData
            ? `url(${getApiImage(topicData?.image?.url)})`
            : 'url()',
          backgroundSize: 'cover',
          minHeight: `calc(100vh - ${appBarHeight}px)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="bg-gray-900 px-16 py-8 bg-opacity-50 max-w-2xl top-1/2">
          <h2 className="text-4xl text-white">{makeTitle()}</h2>
          <p className="mt-4 text-gray-50 font-semibold">
            {topicData && topicData.section1_description}
          </p>

          {/* mt-4 flex flex-col sm:flex-col space-y-2 md:justify-between items-stretch sm:items-center */}
          <div className="mt-4 space-y-4 sm:space-y-2   sm:flex sm:flex-row sm:items-center sm:justify-between">
            <ContainedButton handleClick={handleClick}>
              {topicData && !isEmpty(topicData.section1_button1)
                ? topicData.section1_button1
                : 'View Resources'}
            </ContainedButton>

            <div className="flex flex-col justify-start">
              <div className="relative flex flex-wrap m-0">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="px-[1rem] w-[14rem] lg:w-[17rem] py-3 placeholder-gray-300 font-semibold text-white relative bg-white bg-opacity-20 rounded-full text-sm border border-gray-400 outline-none focus:outline-none focus:ring"
                />
                <span className="z-10 h-full leading-snug font-normal text-center text-gray-100 absolute bg-transparent rounded text-base items-center justify-center w-8 right-3 pr-4 py-3 cursor-pointer">
                  <span onClick={() => onStartSearch(searchTerm)}>
                    <SearchIcon className="h-5 w-5 cursor-pointer" />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <button
            className="text-white hover:text-primary"
            onClick={() => {
              if (location.pathname === '/fund-manager') {
                handleUserType('entrepreneur');
                setUser('/entrepreneur');
              } else if (location.pathname === '/entrepreneur') {
                handleUserType('fundmanager');
                setUser('/fund-manager');
              }
            }}
          >
            {location.pathname === '/fund-manager'
              ? 'Switch to Entrepreneur'
              : 'Switch to Fund Manager'}
          </button>
        </div>
      </div>
      {/* <div className="h-48">Banned</div> */}
    </>
  );
};

export default TopicBanner;
