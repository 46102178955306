//Framer Motion
import { motion } from 'framer-motion';
import resourceMenuVariant from '../../../common/styles/animationVariants/resourceMenuVariant';

//Component
import TypeSub from '../../../common/components/typography/TypeSub';

type AboutMenuProps = {
  handleMouseLeave: () => void;
};

export default function AboutMenu(props: AboutMenuProps) {
  const { handleMouseLeave } = props;
  return (
    <motion.div
      onMouseLeave={handleMouseLeave}
      className="z-[100] bg-background rounded-sm shadow w-72 mt-1 -ml-32 px-8 py-4 pb-0.5 fixed  hover:cursor-default "
      initial={resourceMenuVariant.initial}
      animate={resourceMenuVariant.animate}
      exit={resourceMenuVariant.exit}
    >
      <ul className="p-0 m-0 list-none">
        <li className="mb-1 cursor-pointer text-secondary font-normal">
          <a
            href="https://renewstrategies.com/about/overview"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary no-underline"
          >
            <TypeSub withHoverEffect>Overview</TypeSub>
          </a>
        </li>
        <li className="mb-1 cursor-pointer text-secondary font-normal">
          <a
            href="https://renewstrategies.com/about/teams"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary no-underline"
          >
            <TypeSub withHoverEffect>Team</TypeSub>
          </a>
        </li>
      </ul>
    </motion.div>
  );
}
