import { useState } from 'react';
import ResourceContent, { ResourceType } from '../components/ResourceContent';
import ResourceContainer, { TopicUserProp } from '../ResourceContainer';

const ResourceDetailsContainer = ({ topicUserType }: TopicUserProp) => {
  const [selectedResource, setSelectedResource] = useState<ResourceType>('all');

  return (
    <>
      <ResourceContainer topicUserType={topicUserType}>
        <ResourceContent
          activeResource={selectedResource}
          onSelectResource={setSelectedResource}
        />
      </ResourceContainer>
    </>
  );
};

export default ResourceDetailsContainer;
