import { IconProps } from "../../types";

function CloseIcon({ className, handleClick }: IconProps) {
  return (
    <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="23" height="25" fill="none" viewBox="0 0 23 25" onClick={handleClick}>
      <path fill="#655E50" d="M14.707 12.347L22.357.873a.574.574 0 00.028-.577A.563.563 0 0021.89 0h-4.12a.564.564 0 00-.462.24l-6.084 8.74L5.14.24A.56.56 0 004.68 0H.56a.56.56 0 00-.465.873l7.649 11.474-7.65 11.477a.563.563 0 00-.028.577c.097.181.29.296.495.296h4.12c.183 0 .355-.09.46-.243l6.083-8.739 6.084 8.739a.56.56 0 00.462.243h4.12a.568.568 0 00.495-.296.574.574 0 00-.029-.577l-7.649-11.477z"></path>
    </svg>
  );
}

export default CloseIcon;
