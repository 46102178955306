import { useEffect } from 'react';
import Layout from 'common/layout/Layout';
import LoginBanner from '../components/LoginBanner';

//Redux
import { useAppDispatch } from 'app/hooks';
import {
  fetchLoginData,
  fetchLoginDialogData,
  fetchSignupDialogData,
} from '../loginSlice';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchLoginData());
    dispatch(fetchLoginDialogData());
    dispatch(fetchSignupDialogData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <LoginBanner />
      </Layout>
    </>
  );
};

export default LoginPage;
