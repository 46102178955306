export default function WholeRenewLogo() {
  return (
    <a href="https://renewstrategies.com" target="_blank" rel="noreferrer">
      <img
        src="/renewLogoSvg.svg"
        className="block cursor-pointer relative w-auto h-[8vw] sm:h-[5vw] lg:h-[3.5vw] xl:h-[2.5vw] "
        alt="Renew Logo"
      />
    </a>
  );
}
