type TypeNavProps = {
  children: any;
  light?: boolean;
  className?: string;
};

export default function TypeNav({ children, light, className, ...rest }: TypeNavProps) {
  return (
    <span className={light ? `text-[1.8vw] text-left font-light xl:text-[1.1vw] text-background ${className}` : `text-[1.8vw] text-left font-light xl:text-[1.1vw] ${className}`} {...rest}>
      {children}
    </span>
  );
}
