import { useState } from 'react';

//Formik
import * as Yup from 'yup';
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik';

//Redux
import { useAppSelector } from 'app/hooks';

//Component
import TypeHeader from 'common/components/typography/TypeHeader';

//Icon
import CloseIcon from 'common/components/icons/CloseIcon';

//Utils
// import { toastMessage } from 'utils/alert';
import { useSendApiData } from 'common/hooks/useSendApiData';
import { parseRegisterValidation } from 'utils/validation';

type SignUpDialogProps = {
  handleClose: () => void;
};

type SigninData = {
  name: string;
  email: string;
  company: string;
  user_role: string;
  password: string;
};

const SignUpDialog = ({ handleClose }: SignUpDialogProps) => {
  const { callApi, loading } = useSendApiData('auth/local/register');
  const { signupDialog } = useAppSelector((state) => state.login);
  const [signupLoading, setSignupLoading] = useState(false);
  const [sucess, setSucess] = useState('');
  const initialValues = {
    name: '',
    email: '',
    company: '',
    user_role: '',
    password: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    company: Yup.string().required('company is required'),
    user_role: Yup.string().required('role is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
  });

  const onSubmit = async (
    values: SigninData,
    { setFieldError }: FormikHelpers<SigninData>
  ) => {
    setSignupLoading(true);
    setSucess('');
    await callApi({
      data: { ...values, username: values.email },
      onValidationError: (err) => {
        parseRegisterValidation(err.data, setFieldError);
        setSignupLoading(false);
      },
      onSuccess: (_d, _s) => {
        setSucess('Registered Successfully. Login to Continue.');
        setSignupLoading(false);
        values.name = '';
        values.email = '';
        values.password = '';
        values.user_role = '';
        values.company = '';
      },
    });
  };
  return (
    <div className="absolute top-0 bg-black bg-opacity-50 grid place-items-center z-[100] h-screen w-full">
      <div className="p-auto w-[90%] min-h-[15vw] md:w-[70vw] lg:w-[60vw]  xl:w-[45vw] rounded-[5px] bg-white relative p-[5vw] xl:p-[3vw] z-[1000]">
        <div className="flex relative items-center mb-[2vw]">
          <TypeHeader>
            {signupDialog && signupDialog['title']
              ? signupDialog['title']
              : 'SG2X Portal Sign Up'}
          </TypeHeader>

          <CloseIcon
            className="absolute right-0 w-3 h-3 md:w-4 md:h-4 cursor-pointer"
            handleClick={handleClose}
          />
        </div>
        <div className="mt-[10vw] sm:mt-[4vw] md:mt-[5vw]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="name" className="my-[1vw]">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Full Name"
                          type="text"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <Field name="company" className="my-[1vw]">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Company Name"
                          type="text"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <Field name="user_role" className="my-[1vw]">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Role"
                          type="text"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <Field name="email" className="my-[1vw]">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Business Email"
                          type="email"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <Field name="password">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Password"
                          type="password"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5 text-[15px]">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>

                <p className="text-[green] my-0.5 text-[15px]">
                  {sucess && sucess}
                </p>

                <div className="flex justify-end mt-[8vw] sm:mt-[6vw] md:mt-[8vw] lg:mt-[5vw] xl:mt-[3vw]">
                  <button
                    disabled={isSubmitting || loading || signupLoading}
                    className="bg-primary rounded-[3px]  text-white font-bold py-1 px-10 disabled:opacity-50"
                    type="submit"
                  >
                    Sign Up
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUpDialog;
