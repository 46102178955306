import { IconProps } from "../../types";

const WordDocIcon = ({ className }: IconProps) => {
  return (
    <>
      <svg className={className || "w-6 h-6"} xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none" viewBox="0 0 18 23">
        <path
          fill="#1976D2"
          d="M3.6 0C1.617 0 0 1.59 0 3.539V19.46C0 21.411 1.617 23 3.6 23h10.8c1.983 0 3.6-1.59 3.6-3.539V7.077c0-.94-.876-1.831-2.447-3.345-.218-.211-.453-.446-.677-.664-.22-.217-.46-.42-.674-.635C12.663.888 11.755 0 10.8 0H3.6zm0 1.768h6.552c.652.164.648.93.648 1.714v2.711c0 .486.403.884.9.884h2.7c.896 0 1.8.004 1.8.884v11.5c0 .98-.806 1.77-1.8 1.77H3.6c-.996 0-1.8-.79-1.8-1.77V3.54c0-.978.804-1.77 1.8-1.77zm9.956 7.825a2.31 2.31 0 00-.536.054 1.67 1.67 0 00-.814.444 1.69 1.69 0 00-.535 1.244c0 1.502-.084 2.737-.28 3.51-.1.387-.236.65-.34.774-.101.125-.131.139-.251.139-.025 0-.014.007-.057-.028a.873.873 0 01-.196-.277c-.141-.29-.265-.788-.338-1.327-.148-1.047-.14-2.228-.14-2.819v-.082a.625.625 0 00-.584-.551.629.629 0 00-.654.466c-.282 1.995-.619 3.47-1.013 4.369-.197.449-.407.749-.591.912a.671.671 0 01-.364.167c-.015-.039-.04-.035-.057-.11-.06-.292-.06-.794-.029-1.329.064-1.07.271-2.252.337-2.737.061-.435-.132-.912-.476-1.161-.346-.247-.74-.307-1.126-.247-.77.119-1.557.666-2.024 1.601a.608.608 0 00.014.64.617.617 0 00.58.287.625.625 0 00.53-.372c.298-.599.801-.869 1.098-.913.125-.02.164 0 .167 0-.055.39-.294 1.644-.365 2.82-.034.587-.049 1.161.057 1.658.051.249.137.49.308.719.174.228.51.414.845.414.501 0 .976-.19 1.349-.525s.657-.773.9-1.326c.096-.214.14-.665.224-.912.075.296.1.624.227.884.121.25.277.5.505.691.229.19.541.304.873.304.475 0 .921-.235 1.208-.581.29-.346.468-.753.591-1.244.25-.98.309-2.27.309-3.814 0-.039.068-.288.17-.388.101-.1.214-.193.703-.056a.63.63 0 00.646-.123.61.61 0 00.18-.626.638.638 0 00-.489-.438c-.2-.057-.38-.104-.562-.111z"
        ></path>
      </svg>
    </>
  );
};

export default WordDocIcon;
