import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Redux
import { useAppSelector } from 'app/hooks';

//Cookie
import { useCookies } from 'react-cookie';

//Formik
import * as Yup from 'yup';
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik';

//Component
import TypeHeader from 'common/components/typography/TypeHeader';

//Icon
import CloseIcon from 'common/components/icons/CloseIcon';

//Utils
import { useSendApiData } from 'common/hooks/useSendApiData';
import { parseRegisterValidation } from 'utils/validation';
import { isEmpty } from 'utils/misc';

type LoginDialogProps = {
  handleClose?: () => void;
};

type LoginType = {
  identifier: string;
  password: string;
};

const LoginDialog = ({ handleClose }: LoginDialogProps) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['accessToken']);
  const { loginDialog } = useAppSelector((state) => state.login);
  const [error, setError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const { callApi, loading } = useSendApiData('auth/local');
  const initialValues = {
    identifier: '',
    password: '',
  };

  const validationSchema = Yup.object({
    identifier: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async (
    values: LoginType,
    { setFieldError }: FormikHelpers<LoginType>
  ) => {
    setLoginLoading(true);
    setError('');
    await callApi({
      data: { ...values },
      onValidationError: (err) => {
        if (
          err.data[0] &&
          err.data[0]['messages'] &&
          err.data[0]['messages'][0] &&
          err.data[0]['messages'][0]['message']
        ) {
          let errorMessage = err.data[0]['messages'][0]['message'];
          setError(errorMessage);
        }
        setLoginLoading(false);
        parseRegisterValidation(err.data, setFieldError);
      },
      onSuccess: (_d, _s) => {
        let userType = '';
        if (!isEmpty(_d['user']) && !isEmpty(_d['user']['type'])) {
          if (_d['user']['type'] === 'entrepreneur') {
            userType = 'for_entrepreneur';
          } else if (_d['user']['type'] === 'fundmanager') {
            userType = 'for_fund_manager';
          }
        }
        const token = {
          token: _d.jwt,
          userType,
        };
        setCookie('accessToken', token, { path: '/' });

        navigate('/');
        setLoginLoading(false);
      },
    });
  };

  return (
    <div className="absolute top-0 bg-black bg-opacity-50 grid place-items-center h-full w-screen z-[1000]">
      <div className="p-auto w-[90%] min-h-[15vw] md:w-[70vw] lg:w-[60vw]  xl:w-[45vw] rounded-[5px] bg-white relative p-[5vw] xl:p-[3vw] z-[1000]">
        <div className="flex relative items-center mb-[2vw]">
          <TypeHeader>
            {loginDialog && loginDialog['title']
              ? loginDialog['title']
              : 'SG2X Portal Log In'}
          </TypeHeader>

          <CloseIcon
            className="absolute right-0 w-3 h-3 md:w-4 md:h-4 cursor-pointer"
            handleClick={handleClose}
          />
        </div>
        <div className="mt-[10vw] sm:mt-[4vw] md:mt-[5vw]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="identifier" className="my-[1vw]">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Email"
                          type="email"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <Field name="password">
                  {(props: FieldProps) => {
                    const { field, meta } = props;

                    return (
                      <div className="my-[4vw] sm:my-[3vw] md:my-[4vw] lg:my-[2vw]">
                        <input
                          placeholder="Password"
                          type="password"
                          className="block w-full border-b-2 my-[1vw] p-1"
                          {...field}
                        />
                        <p className="text-[#ff0000] my-0.5 text-[15px]">
                          {meta.touched && meta.error}
                        </p>
                      </div>
                    );
                  }}
                </Field>
                <p className="text-[#ff0000] my-0.5 mb-8 text-[15px]">
                  {error && error}
                </p>
                <Link to="/forgotpassword">
                  <p className="text-primary underline">Forgot Password?</p>
                </Link>

                <div className="flex justify-end mt-[8vw] sm:mt-[6vw] md:mt-[8vw] lg:mt-[5vw] xl:mt-[3vw]">
                  <button
                    disabled={isSubmitting || loading || loginLoading}
                    className="bg-primary rounded-[3px]  text-white font-bold py-1 px-10  disabled:opacity-50"
                    type="submit"
                  >
                    Log In
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginDialog;
